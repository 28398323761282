import React, { useEffect, useCallback, useState } from 'react'
import { Datatablefile } from '../../../Vendor/Datatablefile'
import { GET_ALL_LISTED_TVSHOWS } from '../../../config/content_function'


export const TvshowsContent = () => {

  const [tvshowsdata, settvshowsdata] = useState([])

  const tabledata = {
    type: 'Tvshows',
    heading: `
  <b>Total Entry : </b>${tvshowsdata?.length}
  <b style="margin-left: 2em;">Active :</b> ${tvshowsdata?.filter((item) => (item.status === 1)).length}
  <b style="margin-left: 2em;">Inactive :</b> ${tvshowsdata?.filter((item) => item.status === 0).length}
  `,
    tableData: tvshowsdata,
    spinnerVisible: false,
    titles: ['Id', 'Name', 'Status', 'Start Date', 'End Date'],
    dataIndexes: ['id', 'title', 'status', 'start_date', 'end_date']
  }


  const reloadResponse = useCallback(() => {
    tabledata["spinnerVisible"] = true
    GET_ALL_LISTED_TVSHOWS()
      .then((resp) => {
        console.log(resp)
        if (resp?.code === 200) {
          settvshowsdata(resp?.data)
          tabledata["spinnerVisible"] = false
        }
      })
      .catch((err) => console.log("Data not found Msg :", err))

  }, [])

  useEffect(() => {
    reloadResponse()
  }, [])


  return (
    <div className="wrapper dashboard-bg">
      <Datatablefile data={tabledata} reloadDataTable={reloadResponse} />
    </div>
  )
}
