import { createSlice } from '@reduxjs/toolkit'

export const AuthSlice = createSlice({
    name: 'auth',
    initialState: {
        loginType: '',
        token: '',
        isLogin: false,
        userAccess: [],
        partnerId:'',
        loginData:''
    },
    reducers: {
        setAuthToken: (state, action) => {
            state.token = action
            state.isLogin = true
            return state;
        },
        SignoutUser: (state, action) => {
            state.token = ''
            state.isLogin = false
            return state;
        },
        loginDetails: (state, action) => {
            state.loginType = action?.payload?.type
            state.userAccess = action?.payload?.userRights
            state.loginData = action?.payload
            return state;
        },
        setpartnerId:(state,action)=>{
            console.log(action)
            state.partnerId = action?.payload
            return state;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setAuthToken, SignoutUser, loginDetails,setpartnerId } = AuthSlice.actions

export default AuthSlice.reducer