import React from 'react';
import ReactDOM from 'react-dom/client';
import { RoutePath } from './Routes';
import { persistor, store } from './Redux/store';
// import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import './assets/scss/main.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  // <React.StrictMode>
    <Provider store={store}>
      <RoutePath />
    </Provider>
  // </React.StrictMode>

);
