import { DELETE_KENYA_CITY, GET_GAMES_CONTENT, GET_KENYA_CITIES, GET_MOVIES_CONTENT, GET_MUSICS_CONTENT, GET_TVSHOWS_CONTENT, INSERT_KENYA_CITY, UPDATE_GAMES_CONTENT, UPDATE_MOVIES_CONTENT, UPDATE_MUSICS_CONTENT, UPDATE_TVSHOWS_CONTENT } from "./apiEndpoints"
import { DeleteAPI, GetAPI, PatchAPI, PostAPI } from "./apiMethods"


// Content api methods //////////////////////////////////////////////////////
export const GET_ALL_LISTED_MOVIES = (async (queryParam, type) => {
    return GetAPI(GET_MOVIES_CONTENT)
        .then((data) => {
            return data?.data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_ALL_LISTED_TVSHOWS = (async (queryParam, type) => {
    return GetAPI(GET_TVSHOWS_CONTENT)
        .then((data) => {
            return data?.data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_ALL_LISTED_MUSICS = (async (queryParam, type) => {
    return GetAPI(GET_MUSICS_CONTENT)
        .then((data) => {
            return data?.data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_ALL_LISTED_GAMES = (async (queryParam, type) => {
    return GetAPI(GET_GAMES_CONTENT)
        .then((data) => {
            return data?.data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_ALL_LISTED_KENYA_CITIES = (async (queryParam, type) => {
    return GetAPI(GET_KENYA_CITIES)
        .then((data) => {
            return data?.data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})


// Update Content status or other keys PATCH api
export const UPDATE_A_MOVIE = (async (bodypaload) => {
    return PatchAPI(UPDATE_MOVIES_CONTENT, bodypaload)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const UPDATE_A_TVSHOWS = (async (bodypaload) => {
    return PatchAPI(UPDATE_TVSHOWS_CONTENT, bodypaload)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const UPDATE_A_MUSIC = (async (bodypaload) => {
    return PatchAPI(UPDATE_MUSICS_CONTENT, bodypaload)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const UPDATE_A_GAMES = (async (bodypaload) => {
    return PatchAPI(UPDATE_GAMES_CONTENT, bodypaload)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})


export const INSERT_NEW_CITY = (async (Payload) => {
    return PostAPI(INSERT_KENYA_CITY, Payload)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})


// Delete an city
export const DELETE_A_CITY = (async (CityId) => {
    return DeleteAPI(DELETE_KENYA_CITY + '?city_id=' + CityId)
        .then((data) => {
            return data?.data
        }).catch((error) => {
            console.log(error)
            return error
        })
})

