import React, { useEffect, useState } from 'react'
import { GET_ADS_LIST } from '../../config/advertisement/ad_functions'
import { Datatable } from '../helpers/Datatable'

export const AdsListing = () => {

  const [AdsListingData, setAdsListingData] = useState([])

  useEffect(() => {
    tabledata["spinnerVisible"] = true
    GET_ADS_LIST().then((data) => {
      console.log(data)
      if (data?.status === true) {
        tabledata["spinnerVisible"] = false
        setAdsListingData(data?.data)
      }
    })
      .catch((err) => {
        console.log(err)
      })
  }, [])


  const tabledata = {
    type: 'ads',
    heading: `<b>Total : </b>${AdsListingData?.length}`,
    tableData: AdsListingData,
    spinnerVisible: false,
    titles: ['Id', 'Ad-Name', 'Ad-Type', 'Section', 'Status', 'Format', 'Image'],
    dataIndexes: ['id', 'name', 'advertise_type', 'content_type', 'status', 'file_format', 'url']
  }


  return (
    <section className="content-header">
      <div className="container-fluid">
        <Datatable data={tabledata} />
      </div>
    </section>
  )
}
