import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { SignoutUserFilter } from '../Redux/reducers/filterSlice';
import { SignoutUser } from '../Redux/reducers/authSlice';

export const MagazineHeader = (props) => {
    const urlParams = useLocation()
    const dispatch = useDispatch()
    const loginData = useSelector((state) => state.AuthSlice.loginData)

    const Signout = () => {
        // Signout user
        dispatch(SignoutUser())
        dispatch(SignoutUserFilter())
    }

    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light" >
                <ul className="align-items-center navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <h4 className='font-weight-bold m-0 pl-1' style={{ textTransform: 'capitalize' }}>
                            {props?.pageName ? props?.pageName : 'Magazine Panel'}</h4>
                    </li>
                </ul>

                <ul className="navbar-nav ml-auto">

                    {/* <li className="nav-item">
                        <Link to="/dashboard/settings">
                            <i className="fa-cog fas fs-5 mt-2 mx-2 pt-1 text-dark"></i>
                        </Link>
                    </li> */}

                    <li className="nav-item">
                        <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                            <i className="fas fa-expand-arrows-alt"></i>
                        </a>
                    </li>


                    <li className="nav-item navbar-dropdown dropdown-user dropdown ml-2">
                        <a className="nav-link dropdown-toggle hide-arrow p-0" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="avatar avatar-online">
                                <img src={require('../assets/images/profileImage.png')} alt="" className="w-px-40 h-auto rounded-circle" />
                            </div>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end mt-3 py-2" data-bs-popper="static">
                            <li>
                                <div className="dropdown-item pb-2 mb-1 waves-effect">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2 pe-1">
                                            <div className="avatar avatar-online">
                                                <img src={require('../assets/images/profileImage.png')} alt="" className="w-px-40 h-auto rounded-circle" />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h6 className="mb-0">{loginData?.name}</h6>
                                            <small className="text-muted">{loginData?.email}</small>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-divider my-0"></div>
                            </li>
                            {/* <li>
                                <div className="dropdown-item waves-effect">
                                    <i className="mdi mdi-account-outline me-1 mdi-20px"></i>
                                    <span className="align-middle">My Profile</span>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item waves-effect">
                                    <i className="mdi mdi-cog-outline me-1 mdi-20px"></i>
                                    <span className="align-middle">Settings</span>
                                </div>
                            </li> */}

                            {/* <li>
                                <div className="dropdown-divider"></div>
                            </li> */}

                            <li>
                                <div className="dropdown-item font-weight-bold text-danger waves-effect cursor-pointer" onClick={Signout}>
                                    <span className="align-middle">Log Out</span>
                                </div>
                            </li>
                        </ul>
                    </li>

                </ul>
            </nav>

            <div className="d-flex mb-2 dashboard-content navbar-white navbar-light text-right justify-content-end">
                <div className='d-flex'>

                </div>

            </div>

        </>
    )
}
