import { useCallback, useEffect, useState,useRef } from "react";
import { Button, Checkbox, Form, Input, Select, Spin, Upload } from 'antd';
import { Banner_contentType, Preroll_contentType, advertiseStatus, advertiseType } from "../helpers/constant";
import { InboxOutlined } from '@ant-design/icons';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { GET_ALL_LISTED_MOVIES, GET_ALL_LISTED_TVSHOWS } from "../../config/content_function";
import { MuiltipleSelectPicker } from "../helpers/MultipleSelect";
import { UPLOAD_YOUR_AD } from "../../config/advertisement/ad_functions";
import { UploadOutlined } from '@ant-design/icons';
import Dragger from "antd/es/upload/Dragger";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import moment from "moment";
import { validateAdvertiseFile } from "../../helper";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
export const AdsUpload = () => {

  const navigate = useNavigate()
  const [form] = Form.useForm();
  const toastRef = useRef(null);
  const [adtype, setadtype] = useState('')
  const [contentType, setcontentType] = useState('')
  const [contentIdlist, setcontentIdlist] = useState([])
  const [allcontentId, setallcontentId] = useState([])
  const [selectedContentId, setselectedContentId] = useState([])
  const [fileuploadpath, setfileuploadpath] = useState('')
  const [filevalidate, setfilevalidate] = useState({ 'msg': '', 'warning': false, 'uploadallowed': false })
  const [skipType, setskipType] = useState(false)
  const [skipTime, setskipTime] = useState(0)
  const [formSubmitted, setformSubmitted] = useState(false)

  useEffect(() => {
    if (contentType === 'Movies') {
      GET_ALL_LISTED_MOVIES().then((res) => {
        if (res?.code === 200) {
          let allIds = []
          setcontentIdlist(res?.data);
          res?.data?.map((item) => allIds.push(item.id))
          setallcontentId(allIds);
        }
      }
      ).catch((err) => console.log(err))
    }
    else if (contentType === 'Tvshows') {

      GET_ALL_LISTED_TVSHOWS().then((res) => {
        if (res?.code === 200) {
          let allIds = []
          setcontentIdlist(res?.data);
          res?.data?.map((item) => allIds.push(item.id))
          setallcontentId(allIds);
        }
      }
      ).catch((err) => console.log(err))
    }
  }, [contentType])

  const onFinish = (values) => {
    if (toastRef.current) {
      toast.dismiss(toastRef.current); // Dismiss the previous toast if it exists
  }
    const validateResp = validateAdvertiseFile(adtype, fileuploadpath)
    if (validateResp !== true) {
      setfilevalidate({ 'msg': validateResp, 'warning': true, 'uploadallowed': false })
      return
    }

    if (!filevalidate?.uploadallowed) {
      toastRef.current = toast.error('Upload right format content file', {
        position: "top-right"
      });
      return false
    }

    setformSubmitted(true)
    values["size"] = fileuploadpath.size / 1024 + 'kb'
    values["fileformat"] = fileuploadpath.type
    values["skiptimein_second"] = skipTime
    values["isskip"] = skipType

    if (adtype === 'Pre-roll') {
      if (selectedContentId.length === 0) {
        toastRef.current = toast.error('Choose Content Id', {
          position: "top-right"
        });
        return false
      }
      values["contentids"] = selectedContentId
    }

    delete values.ad_file
    let bodyPayload = {
      'data': values,
      'file': fileuploadpath
    }
    UPLOAD_YOUR_AD(bodyPayload).then((res) => {
      setformSubmitted(false)
      if (res?.data?.code === 200) {
        toastRef.current = toast.success("Data Inserted Successfully !", {
          position: "top-right"
        });
        navigate("/dashboard/AdsListing");
      }
      else {
        toastRef.current = toast.error(res?.response?.data.message, {
          position: "top-right"
        });
      }
    }).catch((err) => console.log(err))
  };


  const normFile = (e) => {
    if (e?.fileList.length) {
      setfileuploadpath(e?.file)
      const validateResp = validateAdvertiseFile(adtype, e?.file)
      if (validateResp !== true) {
        setfilevalidate({ 'msg': validateResp, 'warning': true, 'uploadallowed': false })
      }
      else if ((e?.file.size) / 1024 > 5120) {
        // content greater than 5MB not allowed
        setfilevalidate({ 'msg': 'Max 5MB content size is allowed', 'warning': true, 'uploadallowed': false })
      }
      else {
        setfilevalidate({ 'msg': '', 'warning': false, 'uploadallowed': true })
      }
    }
    else {
      setfileuploadpath('')
      setfilevalidate({ 'msg': 'Upload a file', 'warning': true, 'uploadallowed': false })
    }
  };


  const selectedList = useCallback((data) => {
    console.log(data)
    setselectedContentId(data?.selectedContentId)
  })

  return (
    <div className="content-wrapper">
      <ToastContainer />

      <section className="content-header">
        <div className="container-fluid">
          <Form
            {...formItemLayout}
            form={form}
            name="upload"
            onFinish={onFinish}
            initialValues={{
              // prefix: '86',
            }}
            style={{
              maxWidth: 600,
            }}
            scrollToFirstError
          >
            <Form.Item
              name="name"
              label="Ad Name"
              rules={[
                {
                  type: 'text',
                  message: 'The input is not valid text',
                },
                {
                  required: true,
                  message: 'Please input ad name',
                },
              ]}
            >
              <Input placeholder="Enter advertisement name" />
            </Form.Item>

            <Form.Item
              name="adtype"
              label="Advertise Type"
              rules={[
                {
                  required: true,
                  message: 'Please select ad type!',
                },
              ]}
            >
              <Select placeholder="select your ad type" onChange={(e) => { setadtype(e) }}>
                {
                  advertiseType?.map((item, index) => {
                    return (
                      <Option key={index} value={item} >{item}</Option>
                    )
                  })
                }
              </Select>
            </Form.Item>

            <Form.Item
              name="Status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: 'Please select status!',
                },
              ]}
            >
              <Select placeholder="select status">
                {
                  advertiseStatus?.map((item, index) => {
                    return (
                      <Option key={index} value={item} >{item}</Option>
                    )
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              name="ContentType"
              label="Section Type"
              rules={[
                {
                  required: true,
                  message: 'Please select section!',
                },
              ]}
            >
              <Select placeholder="select section" onChange={(e) => { setcontentType(e) }}>
                {
                  adtype !== '' && adtype === 'Banner' && Banner_contentType?.map((item, index) => {
                    return (
                      <Option key={index} value={item} >{item}</Option>
                    )
                  })
                }
                {
                  adtype !== '' && adtype === 'Pre-roll' && Preroll_contentType?.map((item, index) => {
                    return (
                      <Option key={index} value={item} >{item}</Option>
                    )
                  })
                }
              </Select>
            </Form.Item>

            {
              adtype === 'Pre-roll' && allcontentId?.length !== 0 &&
              <MuiltipleSelectPicker data={{ 'initialids': allcontentId, 'values': contentIdlist }} callbackOnchange={selectedList} />
            }

            <Form.Item name="ad_file" label="Upload File / Img" valuePropName="fileList" getValueFromEvent={normFile} >
              <Dragger
                listType="picture"
                defaultFileList={[]}
                beforeUpload={() => false}
                maxCount='1'
              // onRemove={()=>setfilevalidate({ 'msg': '', 'warning': false, 'uploadallowed': true })}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text font-weight-bold">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint font-weight-bold">Support for a single upload at a time.</p>
              </Dragger>
            </Form.Item>

            {
              filevalidate.warning ?
                <Form.Item {...tailFormItemLayout}>
                  <p className="text-danger">{filevalidate.msg}</p>
                </Form.Item>
                : ''
            }

            {
              adtype !== '' && adtype === 'Pre-roll' &&
              <>
                <Form.Item
                  name="isskip"
                  label={adtype + " skip"}
                >
                  <Select placeholder="Select Option" value={skipType} onChange={(e) => { setskipType(e) }}>
                    <Option value={false}>No</Option>
                    <Option value={true}>Yes</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="skiptimein_second"
                  label="Skip Time in seconds"
                  onChange={(e) => setskipTime(e.target.value)}
                >
                  <Input type="tel" value={skipTime} placeholder="Enter time in seconds e.g 2 , 5 , 10" />
                </Form.Item>
              </>
            }

            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit" disabled={formSubmitted}>
                {formSubmitted ? 'Submitting....' : 'Submit'}
                {formSubmitted ? <Spin indicator={<LoadingOutlined className="fs-6 ml-2 text-white" spin />} /> : ''}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </section></div>
  );
};


