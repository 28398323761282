import { createSlice } from '@reduxjs/toolkit'

export const eventlogSlice = createSlice({
    name: 'eventlogs',
    initialState: {
        value: 0,
        allDeviceList: []
    },
    reducers: {
        alldevicesList: (state, action) => {
            // state.value += 1
            console.log(state.value)
            state.allDeviceList = action.payload[0]
            return state;
        },
        musiclogs: (state, action) => {
            console.log(state.value)
            console.log(action)
        },
        tvshowslogs: (state, action) => {
            console.log(state.value)
            console.log(action)
        },
        gameslogs: (state, action) => {
            console.log(state.value)
            console.log(action)
        },
    },
})

// Action creators are generated for each case reducer function
export const { alldevicesList, musiclogs, tvshowslogs, gameslogs } = eventlogSlice.actions

export default eventlogSlice.reducer