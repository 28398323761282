import { DELETE_NEWSPAPER, GET_ALL_NEWSPAPER, UPLOAD_NEWSPAPER } from "../apiEndpoints"
import { DeleteAPI, GetAPI, PostAPI } from "../apiMethods";

export const UPLOAD_YOUR_PAPER = (async (Payload) => {

    const formdata = new FormData();
    formdata.append("file", Payload['file']);
    formdata.append("thumbnail", Payload['thumbnail']);
    formdata.append("data", JSON.stringify(Payload.data))
    let multipartFormDataAllow = true;

    return PostAPI(UPLOAD_NEWSPAPER, formdata, multipartFormDataAllow)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_PAPER_LIST = (async () => {
    return GetAPI(GET_ALL_NEWSPAPER)
        .then((data) => {
            return data?.data
        })
        .catch((error) => {
            console.log(error);
            return error;
        })
})

// Delete an newspaper
export const DELETE_A_PAPER = (async (paperId) => {
    return DeleteAPI(DELETE_NEWSPAPER + '?paper_id=' + paperId)
        .then((data) => {
            return data?.data
        }).catch((error) => {
            console.log(error)
            return error
        })
})
