import React, { useRef, useState } from 'react'
import logo from '../../assets/images/logo_aerohub.png'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "./styles.css";
import { ForgotPass_VerifyOTP, updateMypassword } from '../../config/auth_functions';
import { Link, useNavigate } from 'react-router-dom';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export const Recoverpassword = () => {
  const [otpValues, setOtpValues] = useState(['', '', '', '']);
  const [otpVerify, setotpVerify] = useState(false)
  const inputRefs = useRef(Array(4).fill(null).map(() => React.createRef()));
  const [Newpassword, setNewpassword] = useState({})
  const [formSubmitted, setformSubmitted] = useState(false)
  const [typePswrd1, setTypePswrd1] = useState("password");
  const [typePswrd2, setTypePswrd2] = useState("password");
  const [icon1, setIcon1] = useState("fas fa-eye-slash");
  const [icon2, setIcon2] = useState("fas fa-eye-slash");
  const navigate = useNavigate()
  const toastRef = useRef(null);


  const mailId = new URLSearchParams(window.location.search)

  const handleInputChange = (index, value) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;

    setOtpValues(newOtpValues);

    // Move focus to the next input
    if (value !== '' && index < 3) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleBackspace = (index) => {
    const newOtpValues = [...otpValues];

    // If the current input is empty, move focus to the previous input
    if (index > 0 && newOtpValues[index] === '') {
      inputRefs.current[index - 1].focus();
    }

    // Clear the current input value
    newOtpValues[index] = '';

    setOtpValues(newOtpValues);
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (toastRef.current) {
      toast.dismiss(toastRef.current); // Dismiss the previous toast if it exists
    }
    const MYOTP = Number(otpValues.toString().replaceAll(",", ''))
    if (String(MYOTP).length !== 4) {
      toastRef.current = toast.error('Invalid OTP', {
        position: "top-right"
      });
      return;
    }
    setformSubmitted(true)
    ForgotPass_VerifyOTP({
      "partnerEmail": mailId.get('mail'),
      "otp": MYOTP
    }).then((res) => {
      if (res?.code === 200) {
        toastRef.current = toast.success(res?.response?.data?.message, {
          position: "top-right"
        });
        setotpVerify(true);
      } else {
        toastRef.current = toast.error(res?.response?.data?.message, {
          position: "top-right"
        });
      }
      setformSubmitted(false)

    }).catch((err) => {
      setformSubmitted(false)
      toastRef.current = toast.error(err, {
        position: "top-right"
      });
      console.log(err)
    })

  }

  const showPassword = () => {
    typePswrd1 === "password" ? setTypePswrd1("text") : setTypePswrd1("password");
    icon1 === "fas fa-eye"
      ? setIcon1("fas fa-eye-slash")
      : setIcon1("fas fa-eye");
  };

  const showConfirmPassword = () => {
    typePswrd2 === "password" ? setTypePswrd2("text") : setTypePswrd2("password");
    icon2 === "fas fa-eye"
      ? setIcon2("fas fa-eye-slash")
      : setIcon2("fas fa-eye");
  };


  const addPassword = (name, value) => {
    setNewpassword({ ...Newpassword, [name]: value })
  }
  const submitNewPass = (e) => {
    e.preventDefault();
    if (toastRef.current) {
      toast.dismiss(toastRef.current); // Dismiss the previous toast if it exists
    }
    console.log(Newpassword)
    if (Newpassword.password !== Newpassword.confirmPassword) {
      toastRef.current = toast.error("Password Not Matched", {
        position: "top-right"
      });
    }
    else {
      let body = {
        "partnerEmail": mailId.get('mail'),
        "password": Newpassword.password,
        "confirmPassword": Newpassword.confirmPassword
      }
      setformSubmitted(true)
      updateMypassword(body).then((res) => {
        if (res?.code === 200) {
          toastRef.current = toast.success(res?.response?.data?.message, {
            position: "top-right"
          });
          navigate("/auth/login")
        } else {
          toastRef.current = toast.error(res?.response?.data?.message, {
            position: "top-right"
          });
        }
        setformSubmitted(false)
      }).catch((err) => {
        setformSubmitted(false)
        toastRef.current = toast.error(err, {
          position: "top-right"
        });
        console.log(err)
      })
    }
  }

  return (
    <>
      <div className="hold-transition login-page ">
        <ToastContainer />
        <div className="login-box position-relative">
          <Link className='text-decoration-none' to="/auth/Forgotpassword">
            <FontAwesomeIcon icon={faArrowLeftLong} /> Go Back</Link>
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <div className="brand-link">
                <img src={logo} alt="Aerohub Logo" className="brand-image"
                  style={{ "opacity": ".8" }} /></div>
            </div>
            <div className="card-body">
              {
                otpVerify ?
                  <p className="login-box-msg">You are only one step a way from your new password, recover your password now.</p>
                  :
                  <>
                    <p className="login-box-msg">Enter the verification code we have sent to your Mail Id </p>
                    <form className="otp-conatiner" id="otp-conatiner" onSubmit={(e) => submitForm(e)}>
                      <div className="otp-subtext">Type your 4 digit code</div>
                      <div className="otp-inputs">
                        {otpValues.map((value, index) => (
                          <input
                            key={index}
                            className="otp-input"
                            type="text"
                            inputMode="numeric"
                            autoComplete="one-time-code"
                            maxLength={1}
                            value={value}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Backspace') {
                                handleBackspace(index);
                              }
                            }}
                            ref={(inputRef) => (inputRefs.current[index] = inputRef)}
                          />
                        ))}
                      </div>
                      <div className="row w-100">
                        <div className="col-12">
                          <button type="submit" className="btn btn-primary btn-block" disabled={formSubmitted}>{formSubmitted ? 'Verifying in....' : "Verify"}</button>
                          {formSubmitted ? <Spin indicator={<LoadingOutlined className="fs-6 ml-2 text-white" spin />} /> : ''}
                        </div>
                      </div>
                    </form>
                    <div className="otp-resend">Didn’t get the code ?  <Link to="/auth/forgotpassword">Resend</Link></div>
                  </>
              }


              {/* // Password Inputs */}
              {
                otpVerify &&
                <form id="new-password" onSubmit={(e) => submitNewPass(e)}>
                  <div className="input-group mb-3">
                    <input type={typePswrd1} className="form-control" onChange={(e) => addPassword('password', e.target.value)} placeholder="Password" required />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span onClick={showPassword} className={icon1}></span>
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input type={typePswrd2} className="form-control" onChange={(e) => addPassword('confirmPassword', e.target.value)} placeholder="Confirm Password" required />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span onClick={showConfirmPassword} className={icon2}></span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button type="submit" className="btn btn-primary btn-block" disabled={formSubmitted}>{formSubmitted ? 'Updating....' : "Update password"}</button>
                      {formSubmitted ? <Spin indicator={<LoadingOutlined className="fs-6 ml-2 text-white" spin />} /> : ''}
                    </div>
                  </div>
                </form>
              }
              <p className="mt-3 mb-1">
                <Link to="/auth/login">Login</Link>
              </p>
            </div>

          </div>
        </div>
      </div >
    </>
  )
}
