import React  from 'react';
import './assets/scss/main.css';
import { Outlet } from 'react-router-dom';

export const App = () => {

  return (
    <React.Fragment>
      <Outlet />
    </React.Fragment>
  );
};
