// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  
  .otp-conatiner {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: left;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .otp-subtext {
    font-size: 0.775rem;
  }
  
  .otp-inputs {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    padding: 20px 0;
  }
  
  .otp-input {
    font-size: 2.25rem;
    text-align: center;
    height: 50px;
    width: 50px;
    border-radius: 8px;
  }
  
  .submit-btn {
    background-color: #009ef7;
    border: none;
    color: #fff;
    padding: calc(0.825rem + 1px) calc(1.75rem + 1px);
    cursor: pointer;
    font-weight: 600;
    border-radius: 0.625rem;
  }
  
  .otp-resend {
    color: #a1a5b7;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
  }`, "",{"version":3,"sources":["webpack://./src/pages/auth/styles.css"],"names":[],"mappings":";EACE;IACE,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,qBAAqB;IACrB,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,uBAAuB;IACvB,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,iDAAiD;IACjD,eAAe;IACf,gBAAgB;IAChB,uBAAuB;EACzB;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;EACpB","sourcesContent":["  \n  .otp-conatiner {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n    justify-content: left;\n    align-items: center;\n    margin-bottom: 1rem;\n  }\n  \n  .otp-subtext {\n    font-size: 0.775rem;\n  }\n  \n  .otp-inputs {\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n    justify-content: center;\n    padding: 20px 0;\n  }\n  \n  .otp-input {\n    font-size: 2.25rem;\n    text-align: center;\n    height: 50px;\n    width: 50px;\n    border-radius: 8px;\n  }\n  \n  .submit-btn {\n    background-color: #009ef7;\n    border: none;\n    color: #fff;\n    padding: calc(0.825rem + 1px) calc(1.75rem + 1px);\n    cursor: pointer;\n    font-weight: 600;\n    border-radius: 0.625rem;\n  }\n  \n  .otp-resend {\n    color: #a1a5b7;\n    font-size: 0.9rem;\n    font-weight: 500;\n    text-align: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
