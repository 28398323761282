import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const VerticalBarchart = (props) => {

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Events Bar chart',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 1000, // Set the maximum value for the y-axis
      },
    },
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'];

  return (
    <>
      <div className="card card-danger">
        <div className="card-header">
          <h3 className="card-title">{props?.data.title}</h3>
          <div className="card-tools">
            <button type="button" className="btn btn-tool" data-card-widget="collapse">
              <i className="fas fa-minus"></i>
            </button>
            <button type="button" className="btn btn-tool" data-card-widget="remove">
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div className="card-body">
          <Bar options={options} data={{
            labels,
            datasets: [
              {
                label: 'Play',
                data: props?.data.playValues,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
              {
                label: 'View all',
                data: props?.data.viewAllValues,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              },
              {
                label: 'Clicks',
                data: props?.data.clickValues,
                backgroundColor: 'rgba(0, 0, 100, 0.1)',
              },
              
            ]
          }} />
        </div>
      </div>
    </>
  );
}
