// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-tabs-nav-list {
    margin: 0 30%;
}

.login-page .card-header,
.register-page .card-header {
    background-color: var(--blue);
    color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/pages/auth/auth.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;;IAEI,6BAA6B;IAC7B,WAAW;AACf","sourcesContent":[".ant-tabs-nav-list {\n    margin: 0 30%;\n}\n\n.login-page .card-header,\n.register-page .card-header {\n    background-color: var(--blue);\n    color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
