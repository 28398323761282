import { UPLOAD_MAGAZINE, GET_ALL_MAGAZINE, DELETE_MAGAZINE } from "../apiEndpoints"
import { DeleteAPI, GetAPI, PostAPI } from "../apiMethods";

export const UPLOAD_YOUR_MAGAZINE = (async (Payload) => {

    const formdata = new FormData();
    formdata.append("file", Payload['file']);
    formdata.append("thumbnail", Payload['thumbnail']);
    formdata.append("data", JSON.stringify(Payload.data))
    let multipartFormDataAllow = true;

    return PostAPI(UPLOAD_MAGAZINE, formdata, multipartFormDataAllow)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_MAGAZINE_LIST = (async () => {
    return GetAPI(GET_ALL_MAGAZINE)
        .then((data) => {
            return data?.data
        })
        .catch((error) => {
            console.log(error);
            return error;
        })
})

// Delete an MAGAZINE
export const DELETE_A_MAGAZINE = (async (magazineId) => {
    return DeleteAPI(DELETE_MAGAZINE + '?magazine_id=' + magazineId)
        .then((data) => {
            return data?.data
        }).catch((error) => {
            console.log(error)
            return error
        })
})
