import React, { useRef, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './auth.css'
import { Login_Me } from '../../config/auth_functions';
import { useDispatch } from 'react-redux';
import { loginDetails, setAuthToken, setpartnerId } from '../../Redux/reducers/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen, faUser } from '@fortawesome/free-solid-svg-icons';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

// import { clearStore } from '../../Redux/reducers/filterSlice';

export const Login = () => {
    const [partnerDetails, setpartnerDetails] = useState([]);
    const [icon, setIcon] = useState(faLock);
    const [typePswrd, setTypePswrd] = useState("password");
    const [formSubmitted, setformSubmitted] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toastRef = useRef(null);

    const detailsUpdate = (e, name) => {
        setpartnerDetails({ ...partnerDetails, [name]: e.target.value })
    }

    const showPassword = () => {
        typePswrd === "password" ? setTypePswrd("text") : setTypePswrd("password");
        icon === faLock
            ? setIcon(faLockOpen)
            : setIcon(faLock);
    };

    const submitForm = (e) => {
        e.preventDefault();
        if (toastRef.current) {
            toast.dismiss(toastRef.current); // Dismiss the previous toast if it exists
        }
        const payload = partnerDetails;
        if (payload['userNameEmail'] && payload['userNameEmail']?.includes('@')) {
            payload['useremail'] = payload['userNameEmail'];
        }
        else {
            payload['username'] = payload['userNameEmail'];
        }
        setformSubmitted(true)

        delete payload.userNameEmail;

        Login_Me(payload).then((data) => {
            // dispatch(loginReducer(data?.data?.type))
            if (data?.code === 200) {
                // dispatch(clearStore());
                dispatch(loginDetails(data?.data))
                toastRef.current = toast.success("Login Successfully !", {
                    position: "top-right"
                });
                sessionStorage.setItem("authToken", data?.data?.AuthToken)
                dispatch(setAuthToken(data?.data?.AuthToken))
                if (data?.data?.partnerId) {
                    dispatch(setpartnerId(data?.data?.partnerId))
                }
                navigate("/dashboard/Home");
            }
            else {
                toastRef.current = toast.error(data?.response?.data.message, {
                    position: "top-right"
                });
            }
            setformSubmitted(false)

        }).catch((err) => {
            setformSubmitted(false)

            toastRef.current = toast.error(err, {
                position: "top-right"
            });
        })
    }

    return (
        <>
            {/* <!-- Log in  Form --> */}
            <ToastContainer />

            <section className="sign-in auth-pages">
                <div className="container">
                    <div className="signin-content">
                        <div className="signin-image">
                            <figure><img src={require('../../assets/images/signin-image.jpg')} alt="Login" /></figure>
                        </div>

                        <div className="signin-form">
                            <h2 className="form-title">Aerohub Login</h2>
                            <form id="login_page" onSubmit={(e) => submitForm(e)}>
                                <div className="form-group">
                                    <label htmlFor="userNameEmail"><FontAwesomeIcon icon={faUser} /></label>
                                    <input type="text" name="userNameEmail" id="your_name" defaultValue={partnerDetails.userNameEmail}
                                        placeholder="User-email / Name" onChange={(e) => detailsUpdate(e, 'userNameEmail')} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password"><FontAwesomeIcon icon={icon} onClick={showPassword} /></label>
                                    <input type={typePswrd} name="password" id="your_pass" defaultValue={partnerDetails.password}
                                        placeholder="Password" onChange={(e) => detailsUpdate(e, 'password')} required />
                                </div>
                                {/* <div className="form-group">
                                    <input type="checkbox" name="remember-me" id="remember-me" className="agree-term" />
                                    <label htmlFor="remember-me" className="label-agree-term">Remember me</label>
                                </div> */}
                                <div className="form-group form-button">
                                    <input type="submit" name="signin" id="signin" className="form-submit" value={formSubmitted ? 'Logging in....' : "Log in"} disabled={formSubmitted} />
                                    {formSubmitted ? <Spin indicator={<LoadingOutlined className="fs-6 ml-2 text-white" spin />} /> : ''}
                                </div>

                                <Link to="/auth/Forgotpassword">I forgot my password</Link>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}