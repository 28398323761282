export const advertiseType = ['Pre-roll']
// Uncomment banner in next version
// export const advertiseType = ['Pre-roll','Banner']

export const advertiseStatus = ['Active','Inactive']

export const Preroll_contentType = ['Movies','Tvshows']

export const Banner_contentType = ['Home','Movie-viewall','Movie-description','Tvshow-viewall','Tvshow-description']

