import React, { useCallback, useEffect, useState } from 'react'
import MyDateRangePicker from '../../Vendor/Rangepicker';
import { useDispatch, useSelector } from 'react-redux';
import { SignoutUser } from '../../Redux/reducers/authSlice';
import SidebarDrawer from '../../Vendor/drawer';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import DataInfoDrawer from '../../Vendor/DataInfoDrawer';
import { SignoutUserFilter } from '../../Redux/reducers/filterSlice';

export const Header = (props) => {
    const urlParams = useLocation()
    const dispatch = useDispatch()
    const selector = useSelector((state) => state.AuthSlice.userAccess)
    const loginData = useSelector((state) => state.AuthSlice.loginData)

    const DateRange = useSelector((state) => state.filter.DateRange)
    const [showDatepicker, setshowDatepicker] = useState(false)
    const [userRights, setuserRights] = useState([])
    const [pathname, setpathname] = useState(urlParams?.pathname)
    const [selectedTimeZone, SetselectedTimeZone] = useState(loginData?.timezone)
    const [ShowTimeZoneModal, setShowTimeZoneModal] = useState(false)


    console.log("header path name", urlParams)
    const getSelectedRange = (data) => {
        setshowDatepicker(data)
    };

    useEffect(() => {
        let parsedData = selector;
        if (typeof (selector) === 'string') {
            parsedData = JSON.parse(selector);
        }
        setuserRights(parsedData?.Header)
        console.log(pathname)
        setpathname(urlParams?.pathname)
    }, [urlParams?.pathname])

    const Signout = () => {
        // Signout user
        dispatch(SignoutUser())
        dispatch(SignoutUserFilter())
    }

    // Timezone update apply click
    const timezoneUpdate = () => {
        console.log(selectedTimeZone)
    }

    const handleTimeZoneModalClick = useCallback(() => {
        timezoneUpdate();
        setShowTimeZoneModal(false);
    }, [])

    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={{ zIndex: ShowTimeZoneModal ? 1067 : 1 }}>
                <ul className="align-items-center navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <h4 className='font-weight-bold m-0 pl-1' style={{ textTransform: 'capitalize' }}>
                            {props?.pageName ? props?.pageName : 'Home'}</h4>
                    </li>
                </ul>

                <ul className="navbar-nav ml-auto">

                    <div className='mr-3 mt-2' style={{display:'none'}}>
                        <b>Change Time Zone : </b>
                        <a href="#" data-toggle="modal" data-target="#TimeZoneModal" onClick={() => setShowTimeZoneModal(true)}>
                            {loginData?.timezone}
                        </a>
                    </div>


                    {/* // Time zone modal */}
                    <div className="modal fade" id="TimeZoneModal" tabIndex="-1" role="dialog" aria-labelledby="TimeZoneModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="TimeZoneModalLabel">Update Time Zone</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <h5>Want to display data in another time zone ?</h5>
                                    <select onChange={(e) => SetselectedTimeZone(e.target.value)} value={selectedTimeZone}>
                                        <option value="IST">IST</option>
                                        <option value="GMT">GMT</option>
                                    </select>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShowTimeZoneModal(true)}>Cancel</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={handleTimeZoneModalClick}>Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <li className="nav-item">
                        <Link to="/dashboard/settings">
                            <i className="fa-cog fas fs-5 mt-2 mx-2 pt-1 text-dark"></i>
                        </Link>
                    </li> */}

                    <li className="nav-item">
                        <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                            <i className="fas fa-expand-arrows-alt"></i>
                        </a>
                    </li>


                    <li className="nav-item navbar-dropdown dropdown-user dropdown ml-2">
                        <a className="nav-link dropdown-toggle hide-arrow p-0" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="avatar avatar-online">
                                <img src={require('../../assets/images/profileImage.png')} alt="" className="w-px-40 h-auto rounded-circle" />
                            </div>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end mt-3 py-2" data-bs-popper="static">
                            <li>
                                <div className="dropdown-item pb-2 mb-1 waves-effect">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2 pe-1">
                                            <div className="avatar avatar-online">
                                                <img src={require('../../assets/images/profileImage.png')} alt="" className="w-px-40 h-auto rounded-circle" />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h6 className="mb-0">{loginData?.name}</h6>
                                            <small className="text-muted">{loginData?.email}</small>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {/* <li>
                                <div className="dropdown-divider my-0"></div>
                            </li> */}
                            {/* <li>
                                <div className="dropdown-item waves-effect">
                                    <i className="mdi mdi-account-outline me-1 mdi-20px"></i>
                                    <span className="align-middle">My Profile</span>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item waves-effect">
                                    <i className="mdi mdi-cog-outline me-1 mdi-20px"></i>
                                    <span className="align-middle">Settings</span>
                                </div>
                            </li> */}
                            {/* <li>
                                <a className="dropdown-item waves-effect" href="pages-account-settings-billing.html">
                                    <span className="d-flex align-items-center align-middle">
                                        <i className="flex-shrink-0 mdi mdi-credit-card-outline me-1 mdi-20px"></i>
                                        <span className="flex-grow-1 align-middle ms-1">Billing</span>
                                        <span className="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">4</span>
                                    </span>
                                </a>
                            </li> */}
                            <li>
                                <div className="dropdown-divider"></div>
                            </li>
                            {
                                userRights?.Profile?.register_device === "show" ?
                                    <li>
                                        <Link to="/auth/device_register" className="dropdown-item">
                                            <span>Register a device </span>
                                        </Link>
                                    </li> : ''
                            }
                            {
                                userRights?.Profile?.register_partner === "show" ?
                                    <>
                                        <li>
                                            <Link to="/auth/partner_register" className="dropdown-item">
                                                <span>Register a partner </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <div className="dropdown-divider my-1"></div>
                                        </li>
                                    </>
                                    : ''}

                            <li>
                                <div className="dropdown-item font-weight-bold text-danger waves-effect cursor-pointer" onClick={Signout}>
                                    <span className="align-middle">Log Out</span>
                                </div>
                            </li>
                        </ul>
                    </li>

                </ul>
            </nav>

            <div className="d-flex mb-2 dashboard-content navbar-white navbar-light text-right justify-content-end">
                <div className='d-flex'>
                    {
                        pathname !== '/dashboard/PartnerListing' && pathname !== '/dashboard/DeviceListing' && pathname !== '/dashboard/Home' ?
                            <div className="mt-2 nav-item flex-fill">
                                <SidebarDrawer />
                            </div> : ''
                    }
                    <div className=" mt-2 mt-sm-0 py-2 pr-1" style={{ "flex": "auto" }}>
                        <MyDateRangePicker picker={getSelectedRange} buttonshowpicker={showDatepicker} />
                        <button className='btn btn-secondary header-btn' onClick={() => setshowDatepicker(!showDatepicker)}>
                            <FontAwesomeIcon icon={faCalendarDays} className="mr-2" /> Select Date Range</button>
                        <p className='pl-2 font-weight-bold'> {DateRange !== '' ? moment(new Date(DateRange?.startDate)).format('DD-MM-YYYY') + '  to  ' + moment(new Date(DateRange?.endDate)).format('DD-MM-YYYY') : ''}</p>
                    </div>
                </div>

            </div>

        </>
    )
}
