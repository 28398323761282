import { useState, useRef } from "react";
import { Button, Form, Input, Select, Spin } from 'antd';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import { INSERT_NEW_CITY } from "../config/content_function";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
export const KenyacityInsert = ({callback}) => {

  const navigate = useNavigate()
  const [form] = Form.useForm();
  const toastRef = useRef(null);
  const [formSubmitted, setformSubmitted] = useState(false)

  const onFinish = (values) => {
    if (toastRef.current) {
      toast.dismiss(toastRef.current); // Dismiss the previous toast if it exists
    }

    setformSubmitted(true)

    let bodyPayload = values
    INSERT_NEW_CITY(bodyPayload).then((res) => {
      setformSubmitted(false)
      if (res?.data?.code === 200) {
        toastRef.current = toast.success("Data Inserted Successfully !", {
          position: "top-right"
        }); 
        callback('citylist')
        // navigate("/dashboard/KenyaCitiesList");
      }
      else {
        toastRef.current = toast.error(res?.response?.data.message, {
          position: "top-right"
        });
      }
    }).catch((err) => console.log(err))
  };

  return (
    <div className="content-wrapper">
      <ToastContainer />

      <section className="content-header">
        <div className="container-fluid">
          <Form
            {...formItemLayout}
            form={form}
            name="upload"
            onFinish={onFinish}
            initialValues={{
              // prefix: '86',
            }}
            style={{
              maxWidth: 600,
            }}
            scrollToFirstError
          >
            <Form.Item
              name="city_name"
              label="City Name"
              rules={[
                {
                  type: 'text',
                  message: 'The input is not valid text',
                },
                {
                  required: true,
                  message: 'Please input city name',
                },
              ]}
            >
              <Input placeholder="Enter city name" />
            </Form.Item>

            <Form.Item
              name="airport_name"
              label="Airport Name"
              rules={[
                {
                  required: true,
                  message: 'Please input airport name',
                },
              ]}
            >
              <Input placeholder="Enter airport name" />

            </Form.Item>

            <Form.Item
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: 'Please select status!',
                },
              ]}
            >
              <Select placeholder="select status">
                <Option key={1} value={1} >Active</Option>
                <Option key={0} value={0} >Inactive</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="terminal_code"
              label="Terminal Code"
              rules={[
                {
                  required: true,
                  message: 'Please input terminal code',
                },
              ]}
            >
              <Input placeholder="Enter terminal code" />

            </Form.Item>

            <Form.Item
              name="country"
              label="Country Name"
              rules={[
                {
                  required: true,
                  message: 'Please input country name',
                },
              ]}
            >
              <Input placeholder="Enter country name" />

            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit" disabled={formSubmitted}>
                {formSubmitted ? 'Submitting....' : 'Submit'}
                {formSubmitted ? <Spin indicator={<LoadingOutlined className="fs-6 ml-2 text-white" spin />} /> : ''}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </section></div>
  );
};


