import React, { useEffect, useState } from 'react'
import { Datatablefile } from '../../../Vendor/Datatablefile'
import { GET_CABBOOKINGS_EVENTS } from '../../../config/form_functions'

export const CabBookings = () => {

  const [bookingsData, setbookingsData] = useState([])

  const tabledata = {
    type: 'cab bookings',
    heading: `<b>Total : </b>${bookingsData?.length}`,
    tableData: bookingsData,
    spinnerVisible: false,
    titles: ['Id', 'Flight', 'Device', 'Pickup', 'Drop', 'Type', 'Pickup Time', 'Synctime'],
    dataIndexes: ['id', 'flight_number', 'deviceId', 'pickup_location', 'drop_location', 'cab_type', 'pickup_date_time', 'syncDatetime']
  }

  useEffect(() => {
    tabledata["spinnerVisible"] = true
    GET_CABBOOKINGS_EVENTS()
      .then((resp) => {
        if (resp?.data?.status === true) {
          setbookingsData(resp?.data?.data)
          tabledata["spinnerVisible"] = false
        }
      })
      .catch((err) => console.log("Data not found Msg :", err))
  }, [])

  return (
    <div className="wrapper dashboard-bg">
      <Datatablefile data={tabledata} />
    </div>
  )
}
