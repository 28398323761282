import React, { useState } from 'react';
import { Drawer, Space } from 'antd';

const DataInfoDrawer = () => {
    const [open, setOpen] = useState(false);
    const [size, setSize] = useState();

    const showDefaultDrawer = () => {
        setSize('default');
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
  
    return (
        <>
            <div className="row mt-4 w-50">
                <div className="col-6">

                    <div className="small-box bg-success">
                        <div className="icon">
                            <i className="ion ion-stats-bars"></i>
                        </div>
                        <div className="inner">
                            <h5>Total devices - 20</h5>
                            <p>Selected devices - all</p>
                        </div>
                        <a href="#" className="small-box-footer" onClick={showDefaultDrawer}>More info <i className="fas fa-arrow-circle-right"></i></a>
                    </div>
                </div>
            </div>

            <Drawer
                title={`Applied Filters & Info`}
                placement="right"
                size={size}
                onClose={onClose}
                open={open}
                extra={
                    <Space></Space>
                }>
                <div className="">

                    <div className="info-box mb-3 bg-info">
                        <span className="info-box-icon"><i className="far fa-comment"></i></span>
                        <div className="info-box-content">
                            <span className="info-box-text">Partner</span>
                            <span className="info-box-number">indigo@aerohub.aero</span>
                        </div>

                    </div>

                    <div className="info-box mb-3 bg-warning">
                        <span className="info-box-icon"><i className="fas fa-tag"></i></span>
                        <div className="info-box-content">
                            <span className="info-box-text">Total Devices</span>
                            <span className="info-box-number">500</span>
                        </div>

                    </div>

                    <div className="info-box mb-3 bg-success">
                        <span className="info-box-icon"><i className="far fa-heart"></i></span>
                        <div className="info-box-content">
                            <span className="info-box-text">Selected Devices</span>
                            <span className="info-box-number">50</span>
                        </div>

                    </div>

                    <div className="info-box mb-3 bg-danger">
                        <span className="info-box-icon"><i className="fas fa-cloud-download-alt"></i></span>
                        <div className="info-box-content">
                            <span className="info-box-text">Date Range</span>
                            <span className="info-box-number">12-02-2024 to 20-03-2024</span>
                        </div>

                    </div>


                </div>
            </Drawer>
        </>
    );
};
export default DataInfoDrawer;