import axios from "axios";
import { APIDomain } from "../env";
// import { useNavigate } from "react-router-dom";
// import { useAuthToken } from "./getToken";

const useApi = (multipart = false) => {
  // const navigate = useNavigate()
  //   const authToken = useAuthToken();
  const api = axios.create({
    baseURL: APIDomain,
    headers: {
      Accept: 'application/json',
      "Content-Type": multipart ? "multipart/form-data" : 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      //   'Authorization': authToken
      'Authorization': sessionStorage["authToken"]
    }
  });
  return api;
};

// Post API
export const PostAPI = async (URL, Body, multipartFormData = false) => {
  const api = useApi(multipartFormData);
  try {
    const response = await api.post(URL, Body);
    return response;
  } catch (error) {
    console.log(error?.response?.data?.message)
    if (error?.response?.code === 403 || error?.response?.code === 401) {
      // navigate("/auth/login")
      window.location.href = "/auth/login"
    }
    throw error;
  }
};

// Get API
export const GetAPI = async (path, data = {}) => {
  const api = useApi();

  try {
    const response = await api.get(path, { params: data });
    return response;
  } catch (error) {
    console.log(error?.response?.data?.message)
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      //   navigate("/auth/login")
      window.location.href = "/auth/login"
    }
    throw error;
  }
};


// Delete API
export const DeleteAPI = async (path, data = {}) => {
  const api = useApi();
  try {
    const response = await api.delete(path, { params: data });
    return response
  }
  catch (error) {
    console.log(error)
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      // navigate("/auth/login")
      window.location.href = "/auth/login"
    }
    throw error;
  }
}

//PATCH API 
export const PatchAPI = async (path, body) => {
  const api = useApi();
  try {
    const response = await api.patch(path, body)
    return response
  }
  catch (error) {
    console.log(error)
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      // navigate("/auth/login")
      window.location.href = "/auth/login"
    }
    throw error;
  }
}