import { createSlice } from '@reduxjs/toolkit'
import { ConvertDateTime } from '../../Vendor/Moment';

// Get today's date
const today = new Date();

// Get the date from 7 days ago
const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(today.getDate() - 7);

export const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        DateRange: {
            startDate: ConvertDateTime(sevenDaysAgo, 'YYYY-MM-DD'),
            endDate: ConvertDateTime(today, 'YYYY-MM-DD')
        },
        FilteredItems: {},
    },
    reducers: {
        dataFilter: (state, action) => {
            state.FilteredItems = action.payload
            return state;
        },
        SignoutUserFilter: (state, action) => {
            return {
                ...state, DateRange: {
                    startDate: ConvertDateTime(sevenDaysAgo, 'YYYY-MM-DD'),
                    endDate: ConvertDateTime(today, 'YYYY-MM-DD')
                }, FilteredItems : {}
                // state.DateRange.startDate =  ConvertDateTime(sevenDaysAgo, 'YYYY-MM-DD'),
                // state.DateRange.endDate = ConvertDateTime(today, 'YYYY-MM-DD')
            }
        },
        updateDateRange: (state, action) => {
            return {
                ...state, DateRange: {
                    startDate: ConvertDateTime(action?.payload?.startDate, 'YYYY-MM-DD'),
                    endDate: ConvertDateTime(action?.payload?.endDate, 'YYYY-MM-DD')
                }
            }
        },
        RemoveFilterData: (state, action) => {
            state.FilteredItems = {}
            return state

        }
    },
})

// Action creators are generated for each case reducer function
export const { dataFilter, updateDateRange, RemoveFilterData,SignoutUserFilter } = filterSlice.actions

export default filterSlice.reducer