import { useState ,useRef} from "react";
import { Button, DatePicker, Form, Input, Select, Space, Spin, Upload, } from 'antd';
import { MagazineLanguage, MagazineStatus } from "../helpers/constant";
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Dragger from "antd/es/upload/Dragger";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import moment from "moment";
import { UPLOAD_YOUR_MAGAZINE } from "../../config/magazine/magazine_functions";
import { validateImage, validatePDFFile } from "../../helper";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export const MagazineUpload = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const toastRef = useRef(null);
  const [fileuploadpath, setfileuploadpath] = useState('')
  const [thumbnailuploadpath, setthumbnailuploadpath] = useState('')
  const [magazineDate, setmagazineDate] = useState('')
  const [filevalidate, setfilevalidate] = useState({ 'msg': '', 'warning': false, 'uploadallowed': false })
  const [thumbnailvalidate, setthumbnailvalidate] = useState({ 'msg': '', 'warning': false, 'uploadallowed': false })

  const [formSubmitted, setformSubmitted] = useState(false)

  /////////////////////////////////////////////////////////////////////
  const onFinish = (values) => {
    if (toastRef.current) {
      toast.dismiss(toastRef.current); // Dismiss the previous toast if it exists
  }
    if (magazineDate === '') {
      toastRef.current = toast.error('Select Magazine Month', {
        position: "top-right"
      });
      return false
    }

    if (!thumbnailvalidate?.uploadallowed) {
      toastRef.current = toast.error('Upload right format thumbnail', {
        position: "top-right"
      });
      return false
    }

    if (!filevalidate?.uploadallowed) {
      toastRef.current = toast.error('Upload right format content file', {
        position: "top-right"
      });
      return false
    }

    setformSubmitted(true)
    values["size"] = fileuploadpath.size / 1024 + 'kb'
    values["fileformat"] = fileuploadpath.type
    values["magazineDate"] = magazineDate
    delete values.magazine_file;
    delete values.news_thumbnail;


    let bodyPayload = {
      'data': values,
      'file': fileuploadpath,
      'thumbnail': thumbnailuploadpath
    }

    UPLOAD_YOUR_MAGAZINE(bodyPayload).then((res) => {
      setformSubmitted(false)
      if (res?.data?.code === 200) {
        toastRef.current = toast.success("Paper Upload Successfully !", {
          position: "top-right"
        });
        navigate("/dashboard/MagazineListing");
      }
      else {
        toastRef.current = toast.error(res?.response?.data.message, {
          position: "top-right"
        });
      }
    }).catch((err) => console.log(err))
  };

  /////////////////////////////////////////////////////////////////////
  const normFile = (e) => {
    if (e?.fileList.length) {
      setfileuploadpath(e?.file)
      const validateResp = validatePDFFile(e?.file)
      console.log(e?.file)
      if (validateResp !== true) {
        setfilevalidate({ 'msg': validateResp, 'warning': true, 'uploadallowed': false })
      }
      else if ((e?.file.size) / 1024 > 50000) {
        // content greater than 5MB not allowed
        setfilevalidate({ 'msg': 'Max 50MB content size is allowed', 'warning': true, 'uploadallowed': false })
      }
      else {
        setfilevalidate({ 'msg': '', 'warning': false, 'uploadallowed': true })
      }
    }
    else {
      setfileuploadpath('')
      setfilevalidate({ 'msg': 'Upload a file', 'warning': true, 'uploadallowed': false })
    }
  };

  const thumbnailFile = (e) => {
    if (e?.fileList.length) {
      setthumbnailuploadpath(e?.file)
      const validateResp = validateImage(e?.file)
      console.log(e?.file)
      if (validateResp !== true) {
        setthumbnailvalidate({ 'msg': validateResp, 'warning': true, 'uploadallowed': false })
      }
      // getting e?.file.size in KB bytes format
      else if ((e?.file.size) / 1024 > 6000) {

        // content greater than 5MB not allowed
        setthumbnailvalidate({ 'msg': 'Max 5MB content size is allowed', 'warning': true, 'uploadallowed': false })
      }
      else {
        setthumbnailvalidate({ 'msg': '', 'warning': false, 'uploadallowed': true })
      }
    }
    else {
      setthumbnailuploadpath('')
      setthumbnailvalidate({ 'msg': 'Upload a thumbnail', 'warning': true, 'uploadallowed': false })
    }
  };


  const onChangedate = (date, dateString) => {
    setmagazineDate(dateString)
  };


  return (
    <div className="content-wrapper">
      <ToastContainer />

      <section className="content-header">
        <div className="container-fluid">
          <Form
            {...formItemLayout}
            form={form}
            name="upload"
            onFinish={onFinish}
            initialValues={{
              // prefix: '86',
            }}
            style={{
              maxWidth: 600,
            }}
            scrollToFirstError
          >
            <Form.Item
              name="name"
              label="Magazine Name"
              rules={[
                {
                  type: 'text',
                  message: 'The input is not valid text',
                },
                {
                  required: true,
                  message: 'Please input ad name',
                },
              ]}
            >
              <Input placeholder="Enter Magazine name" />
            </Form.Item>

            <Form.Item
              name="language"
              label="Magazine Language"
              rules={[
                {
                  required: true,
                  message: 'Please select language',
                },
              ]}
            >
              <Select placeholder="Select your magazine language" >
                {
                  MagazineLanguage?.map((item, index) => {
                    return (
                      <Option key={index} value={item} >{item}</Option>
                    )
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              name="Status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: 'Please select status!',
                },
              ]}
            >
              <Select placeholder="Select status">
                {
                  MagazineStatus?.map((item, index) => {
                    return (
                      <Option key={index} value={item} >{item}</Option>
                    )
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              name="magazineDate"
              label="Magazine Date"
            >
              <Space direction="vertical" size={100}>
                <DatePicker onChange={onChangedate} picker="month" needConfirm disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))} />
              </Space>
            </Form.Item>

            <Form.Item name="ad_thumbnail" label="Upload thumbnail" valuePropName="fileList" getValueFromEvent={thumbnailFile} >
              <Upload
                listType="picture"
                beforeUpload={() => false}
                maxCount='1'
                defaultFileList={[]}
              >
                <Button icon={<UploadOutlined />}>Upload image</Button>
              </Upload>
            </Form.Item>
            {
              thumbnailvalidate.warning ?
                <Form.Item {...tailFormItemLayout}>
                  <p className="text-danger">{thumbnailvalidate.msg}</p>
                </Form.Item>
                : ''
            }

            <Form.Item name="magazine_file" label="Upload Magazine PDF" valuePropName="fileList" getValueFromEvent={normFile} >
              <Dragger
                listType="picture"
                defaultFileList={[]}
                beforeUpload={() => false}
                maxCount='1'
              // onRemove={()=>setfilevalidate({ 'msg': '', 'warning': false, 'uploadallowed': true })}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text font-weight-bold">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint font-weight-bold">Support for a single upload at a time.</p>
              </Dragger>
            </Form.Item>

            {
              filevalidate.warning ?
                <Form.Item {...tailFormItemLayout}>
                  <p className="text-danger">{filevalidate.msg}</p>
                </Form.Item>
                : ''
            }

            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit" disabled={formSubmitted}>
                {formSubmitted ? 'Submitting....' : 'Submit'}
                {formSubmitted ? <Spin indicator={<LoadingOutlined className="fs-6 ml-2 text-white" spin />} /> : ''}
              </Button>
            </Form.Item>
          </Form>

        </div>
      </section ></div >
  );
};


