import React from 'react'

export const AdvertiseHome = () => {
    return (
        <div className="container-fluid">
            {/* <h4 className='mb-2'>Data Content Records</h4> */}
            <div className='w-100'>
            <img width={'100%'} height={'100%'} className='rounded-3' src={require('../../../assets/images/advertisement/plane.png')}/>
            </div>
        </div>

    )
}
