import React, { useRef, useState } from 'react'
import logo from '../../assets/images/logo_aerohub.png'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { SentPasswordMail } from '../../config/auth_functions'
import { Link, useNavigate } from 'react-router-dom';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export const Forgotpassword = () => {
  const [email, setemail] = useState('')
  const navigate = useNavigate()
  const [formSubmitted, setformSubmitted] = useState(false)
  const toastRef = useRef(null);

  const submitForm = (e) => {
    e.preventDefault();
    if (toastRef.current) {
      toast.dismiss(toastRef.current); // Dismiss the previous toast if it exists
    }
    if (email === '') {
      toastRef.current = toast.error('Enter Registered Mail Id', {
        position: "top-right"
      });
      return;
    }
    else {
      setformSubmitted(true)
      SentPasswordMail({
        partnerEmail: email
      }).then((res) => {
        if (res?.code === 200) {
          toastRef.current = toast.success(res?.response?.data?.message, {
            position: "top-right"
          });
          navigate(`/auth/recoverpassword?mail=${email}`);
        } else {
          toastRef.current = toast.error(res?.response?.data?.message, {
            position: "top-right"
          });
        }
        setformSubmitted(false)
      }).catch((error) => {
        setformSubmitted(false)
        toastRef.current = toast.error(error, {
          position: "top-right"
        });
        // Handle errors here if needed
        console.error(error);
      });
    }
  }

  return (
    <>
      <div className="hold-transition login-page">
        <ToastContainer />
        <div className="login-box">
          <Link className='text-decoration-none' to="/auth/login">
            <FontAwesomeIcon icon={faArrowLeftLong} /> Go Back</Link>
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <div className="brand-link">
                <img src={logo} alt="Aerohub Logo" className="brand-image"
                  style={{ "opacity": ".8" }} /></div>
            </div>
            <div className="card-body">
              <p className="login-box-msg">You forgot your password? Here you can easily retrieve a new password.</p>
              <form id="forgotPassword" onSubmit={(e) => submitForm(e)}>
                <div className="input-group mb-3">
                  <input type="email" className="form-control" name="email" onChange={(e) => setemail(e.target.value)} placeholder="Email" />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary btn-block" disabled={formSubmitted}>{formSubmitted ? 'Requesting....' : "Request new password"}</button>
                    {formSubmitted ? <Spin indicator={<LoadingOutlined className="fs-6 ml-2 text-white" spin />} /> : ''}
                  </div>
                </div>
              </form>
              <p className="mt-3 mb-1">
                <Link to="auth/login">Login</Link>
              </p>
            </div>

          </div>
        </div>

      </div>
    </>
  )
}
