import React, { useEffect } from 'react'
import { Header } from '../../Header'
import { Sidebar } from '../../Sidebar'
import { Footer } from '../../Footer'
import { Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import '../../../assets/scss/main.css';
import { AdvertiseHeader } from '../../../Advertise/AdvertiseHeader'
import { AdvertiseFooter } from '../../../Advertise/AdvertiseFooter'
import { AdvertiseSidebar } from '../../../Advertise/AdvertiseSidebar'
// import { NewspaperHeader } from '../../../Newspaper/NewspaperHeader'
// import { NewspaperFooter } from '../../../Newspaper/NewspaperFooter'
// import { NewspaperSidebar } from '../../../Newspaper/NewspaperSidebar'
import { MagazineHeader } from '../../../Magazine/MagazineHeader'
import { MagazineFooter } from '../../../Magazine/MagazineFooter'
import { MagazineSidebar } from '../../../Magazine/MagazineSidebar'

export const Dashboard = () => {
    const isAuthenticated = useSelector((state) => state.AuthSlice.isLogin);
    const loginData = useSelector((state) => state.AuthSlice.loginData)

    const history = useLocation()

    useEffect(() => {
    //   const body = document.body;
    //   body.classList.add('sidebar-mini', 'layout-fixed', 'sidebar-closed', 'sidebar-collapse');

    //   const sidebarMenu = document.getElementsByClassName('sidebar')[0]
    //   sidebarMenu.classList.add('os-host', 'os-theme-light', 'os-host-resize-disabled', 'os-host-scrollbar-horizontal-hidden', 'os-host-transition', 'os-host-overflow', 'os-host-overflow-y')


    }, [history]);
  
    return (
        <>
            <React.Fragment>
                {
                    loginData?.type === "advertise_admin" ?
                        <>
                            {isAuthenticated && <AdvertiseHeader />}
                            {isAuthenticated && <AdvertiseSidebar />}
                            <Outlet />
                            {isAuthenticated && <AdvertiseFooter />}

                         {/* </> :
                        loginData?.type === "news_admin" ?
                            <>
                                {isAuthenticated && <NewspaperHeader />}
                                {isAuthenticated && <NewspaperSidebar />}
                                <Outlet />
                                {isAuthenticated && <NewspaperFooter />} */}

                            </> : 
                            loginData?.type === "magazine_admin" ?
                                <>
                                    {isAuthenticated && <MagazineHeader />}
                                    {isAuthenticated && <MagazineSidebar />}
                                    <Outlet />
                                    {isAuthenticated && <MagazineFooter />}

                                </> :
                                <>
                                    {isAuthenticated && <Header />}
                                    {isAuthenticated && <Sidebar />}
                                    <Outlet />
                                    {isAuthenticated && <Footer />}
                                </>
                }
            </React.Fragment>
        </>
    )
}
