import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { GET_REGISTERED_PARTNERS, REGISTER_A_DEVICE } from '../../config/device_functions';
import { Link, useNavigate } from 'react-router-dom';

export const Device_register = () => {
  const [deviceDetails, setdeviceDetails] = useState([]);
  // const [allowTNC, setallowTNC] = useState(true)
  const [parthnerList, setpartnerList] = useState([])
  const navigate = useNavigate()

  const detailsUpdate = (e, name) => {
    setdeviceDetails({ ...deviceDetails, [name]: e.target.value })
  }

  const submitForm = (e) => {
    e.preventDefault();
    const payload = deviceDetails;
    payload["deviceType"] = 'IFE';
    // if (!allowTNC) {
    //   toast.error("Allow terms and conditions", {
    //     position: "top-right"
    //   });
    //   return;
    // }

    REGISTER_A_DEVICE(payload).then((data) => {
      if (data?.data.code === 200) {
        toast.success("Device Registered Successfully !", {
          position: "top-right"
        });
        // Redirect after 3 seconds
        setTimeout(() => {
          navigate("/dashboard/Home");
        }, 3000);
      }
      else {
        toast.error(data?.data?.message, {
          position: "top-right"
        });
      }
    }).catch((err) => {
      toast.error(err, {
        position: "top-right"
      });
    })
  }

  useEffect(() => {
    GET_REGISTERED_PARTNERS().then((resp) => {
      if (resp?.status === 200) {
        setpartnerList(resp?.data?.data)
      }
      else {
        toast.error("Partner list not found", {
          position: "top-right"
        });
      }
    })
  }, [])

  return (
    <>

      <div class="page-wrapper registration-page p-t-80 p-b-80 font-robo">
        <ToastContainer />

        <div class="wrapper wrapper--w960">
          <div class="card card-2">

            <div class="card-heading">
              <img src={require('../../assets/images/register.png')} alt="device_img" />
            </div>
            <div class="card-body">
              <h2 class="mb-4">Device Register</h2>
              <form id="device_register" onSubmit={(e) => submitForm(e)}>
                <div class="form-field">
                  <label>Device name</label>
                  <input type="text" placeholder="Enter device name" required name="deviceName"
                    onChange={(e) => detailsUpdate(e, 'deviceName')} autocomplete="off" />
                </div>

                <div class="form-field">
                  <label>Device id</label>
                  <input type="text" placeholder="Enter device id" required name="deviceId"
                    onChange={(e) => detailsUpdate(e, 'deviceId')} autocomplete="off" />
                </div>

                <div class="form-field">
                  <label>Aircraft Hexa Code</label>
                  <input type="text" placeholder="Enter hexa code" required name="aircraft_hexacode"
                    onChange={(e) => detailsUpdate(e, 'aircraft_hexacode')} autocomplete="off" />
                </div>

                <div class="row row-space form-field">
                  <div class="col-2">
                    <div class="form-field">
                      <label>Partner name</label>
                      <select className="select2 w-100" onChange={(e) => detailsUpdate(e, 'partnerId')} defaultValue="" required>
                        <option disabled value="">Select partner</option>
                        {
                          parthnerList?.map((obj) => {
                            return <option key={obj.id} value={obj.partnerId}>{obj.partnerName}</option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-field">
                      <label>Device type</label>
                      <select className="select2 w-100" onChange={(e) => detailsUpdate(e, 'devicetype')} required>
                        <option value="IFE">IFE</option>
                      </select>
                    </div>
                  </div>
                </div>


                <div class="">
                  <label>Description</label>
                  <textarea onChange={(e) => detailsUpdate(e, 'description')}>
                  </textarea></div>

                <div class="p-t-20">
                  <Link to="/dashboard/Home" class="btn btn--radius bg-gradient-dark mr-4">Go Back</Link>
                  <button class="btn btn--radius btn--green" type="submit">Register</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}