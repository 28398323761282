import React from 'react'
import { Link } from 'react-router-dom'

export const Register = () => {
  return (
    <>
      <div class="hold-transition register-page">
        <div class="register-box">
          <div class="card card-outline card-primary">
            <div class="card-header text-center">
              <div class="h1"><b>Aerohub</b></div>
            </div>
            <div class="card-body">
              <p class="login-box-msg">Register a new membership</p>
              <form action="../../index.html" method="post">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="Full name" />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-user"></span>
                    </div>
                  </div>
                </div>
                <div class="input-group mb-3">
                  <input type="email" class="form-control" placeholder="Email" />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
                <div class="input-group mb-3">
                  <input type="password" class="form-control" placeholder="Password" />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                <div class="input-group mb-3">
                  <input type="password" class="form-control" placeholder="Retype password" />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <div class="icheck-primary">
                      <input type="checkbox" id="agreeTerms" name="terms" value="agree" />
                      <label for="agreeTerms">
                        I agree to the terms
                      </label>
                    </div>
                  </div>

                  <div class="col-4">
                    <button type="submit" class="btn btn-primary btn-block">Register</button>
                  </div>

                </div>
              </form>
              <div class="social-auth-links text-center">
                <Link to="#" class="btn btn-block btn-primary">
                  <i class="fab fa-facebook mr-2"></i>
                  Sign up using Facebook
                </Link>
                <Link to="" class="btn btn-block btn-danger">
                  <i class="fab fa-google-plus mr-2"></i>
                  Sign up using Google+
                </Link>
              </div>
              <Link to="/auth/login" class="text-center">I am already a member</Link>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
