import React, { useEffect, useState } from 'react'
import { Datatablefile } from '../../../Vendor/Datatablefile'
import { GET_REGISTERED_DEVICES } from '../../../config/device_functions'
import { useSelector } from 'react-redux'

export const DeviceListing = () => {

  let selectorPartnerId = useSelector((state) => state.AuthSlice.partnerId)
  let loginType = useSelector((state) => state.AuthSlice.loginType)
  const [Devices, SetDevices] = useState([])

  const tabledata = {
    type: 'device',
    heading: `<b>Total : </b>${Devices?.length}`,
    tableData: Devices,
    spinnerVisible: false,
    titles: ['Id', 'DeviceId', 'Device Name', 'PartnerId', 'Status', 'Register Time'],
    dataIndexes: ['id', 'deviceId', 'deviceName', 'partnerId', 'deviceStatus', 'createdAt']
  }

  const loadDevices = () => {
    tabledata["spinnerVisible"] = true
    GET_REGISTERED_DEVICES(selectorPartnerId, loginType)
      .then((resp) => {
        if (resp?.status === 200) {
          SetDevices(resp?.data?.data)
          tabledata["spinnerVisible"] = false
        }
      })
      .catch((err) => console.log("Data not found Msg :", err))
  }

  useEffect(() => {
    loadDevices()
  }, [])


  const loadDevicesAgain = () => {
    loadDevices()
  }


  return (
    <div className="wrapper dashboard-bg">
      {/* <div className="preloader flex-column justify-content-center align-items-center">
        <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60" />
      </div> */}

      <Datatablefile data={tabledata} callbackLoadDevices={loadDevicesAgain} />
    </div>
  )
}
