import React from 'react'

export const AdvertiseFooter = () => {
    return (
        <>
            <footer className="main-footer">
                <strong> <span>Aerohub Analytics</span></strong>
                <div className="float-right d-none d-sm-inline-block">
                    {/* <b>Version</b> 3.2.0 */}
                </div>
            </footer>

            <aside className="control-sidebar control-sidebar-dark">
            </aside>
        </>

    )
}
