import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { REGISTER_A_PARTNER } from '../../config/device_functions';
import { ConvertDateTime } from '../../Vendor/Moment';
import { Link, useNavigate } from 'react-router-dom';

export const Partner_register = () => {
  const [partnerDetails, setpartnerDetails] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());
  // const [allowTNC, setallowTNC] = useState(true)
  const [typePswrd1, setTypePswrd1] = useState("password");
  const [typePswrd2, setTypePswrd2] = useState("password");
  const [icon1, setIcon1] = useState("fas fa-eye-slash");
  const [icon2, setIcon2] = useState("fas fa-eye-slash");
  const navigate = useNavigate()


  const showPassword = () => {
    typePswrd1 === "password" ? setTypePswrd1("text") : setTypePswrd1("password");
    icon1 === "fas fa-eye"
      ? setIcon1("fas fa-eye-slash")
      : setIcon1("fas fa-eye");
  };

  const showConfirmPassword = () => {
    typePswrd2 === "password" ? setTypePswrd2("text") : setTypePswrd2("password");
    icon2 === "fas fa-eye"
      ? setIcon2("fas fa-eye-slash")
      : setIcon2("fas fa-eye");
  };

  const detailsUpdate = (e, name) => {
    setpartnerDetails({ ...partnerDetails, [name]: e.target.value })
  }

  const submitForm = (e) => {
    e.preventDefault();
    const payload = partnerDetails;
    if (!payload['partnerLanguage']) {
      payload['partnerLanguage'] = 'English'
    }
    if (!payload['timeZone']) {
      payload['timeZone'] = 'GMT'
    }
    payload['partnerLogo'] = ""
    payload['partnerStartDate'] = ConvertDateTime(startDate, 'YYYY-MM-DD')
    payload['partnerEndDate'] = ConvertDateTime(EndDate, 'YYYY-MM-DD')
    payload['type'] = 'partner'

    // if (!allowTNC) {
    //   toast.error("Allow terms and conditions", {
    //     position: "top-right"
    //   });
    //   return;
    // }

    if (payload['password'] !== payload['confirmedPassword']) {
      toast.error("Password not matched !", {
        position: "top-right"
      });
      return;
    }

    REGISTER_A_PARTNER(payload).then((data) => {
      if (data?.data?.code === 200) {
        toast.success("Partner Registered Successfully !", {
          position: "top-right"
        });
        // Redirect after 3 seconds
        setTimeout(() => {
          navigate("/dashboard/Home");
        }, 2000);
      }
      else {
        if (data?.response?.data) {
          toast.error(data?.response?.data?.message, {
            position: "top-right"
          });
        }
      }
    }).catch((err) => {
      toast.error(err, {
        position: "top-right"
      });
    })
  }

  return (
    <>

      <div className="page-wrapper registration-page p-t-80 p-b-80 font-robo">
        <ToastContainer />

        <div className="wrapper wrapper--w960">
          <div className="card card-2">
            <div className="card-heading partner_registerhead">
              <img src={require('../../assets/images/partner_register.png')} alt="partner_img" />
            </div>
            <div className="card-body">
              <h2 className="mb-4">Partner Register</h2>
              <form id="partner_register" onSubmit={(e) => submitForm(e)}>
                <div className="form-field">
                  <label>Partner Name</label>
                  <input type="text" placeholder="Enter partner name" defaultValue={partnerDetails.partnerName}
                    name="partnerName" onChange={(e) => detailsUpdate(e, 'partnerName')} autoComplete="off" required />
                </div>

                <div className="form-field">
                  <label>Partner Email</label>
                  <input type="email" placeholder="Enter device id" defaultValue={partnerDetails.partnerEmail}
                    name="partnerEmail" onChange={(e) => detailsUpdate(e, 'partnerEmail')} autoComplete="off" required />
                </div>

                <div className="row row-space form-field">
                  <div className="col-2">
                    <div className="form-field">
                      <label>Password</label>
                      <div className='d-flex'>
                        <input type={typePswrd1} placeholder="Enter password" defaultValue={partnerDetails.password}
                          name="password" onChange={(e) => detailsUpdate(e, 'password')} autoComplete="off" required />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <span onClick={showPassword} className={icon1}></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="form-field">
                      <label>Confirm Password</label>
                      <div className='d-flex'>
                        <input type={typePswrd2} placeholder="Confirm password"
                          defaultValue={partnerDetails.confirmedPassword}
                          name="confirmedPassword" onChange={(e) => detailsUpdate(e, 'confirmedPassword')} autoComplete="off" required />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <span onClick={showConfirmPassword} className={icon2}></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row-space form-field">
                  <div className="col-2">
                    <div className="form-field" id="partnerStartdate" data-target-input="nearest">
                      <label>Start Date:</label>
                      <DatePicker className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select" />
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="form-field" id="partnerEnddate" data-target-input="nearest">
                      <label>End Date:</label>
                      <DatePicker className="form-control" selected={EndDate} onChange={(date) => setEndDate(date)} peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select" />
                    </div>
                  </div>
                </div>

                <div className="row row-space form-field">
                  <div className="col-2">
                    <div className="form-field">
                      <label>Language</label>
                      <select className="form-control select2 w-100"
                        defaultValue={partnerDetails.partnerLanguage}
                        onChange={(e) => detailsUpdate(e, 'partnerLanguage')} required>
                        <option disabled defaultValue="">Select language</option>
                        <option>English</option>
                        <option>French</option>
                        <option>German</option>
                        <option>Japanese</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="form-field">
                      <label>Time Zone</label>
                      <select className="form-control select2 w-100"
                        defaultValue={partnerDetails.timeZone}
                        onChange={(e) => detailsUpdate(e, 'timeZone')} required>
                        <option disabled defaultValue="">Select timezone</option>
                        <option>GMT</option>
                        <option>IST</option>
                      </select>
                    </div>
                  </div>
                </div>


                <div className="">
                  <label>Description</label>
                  <textarea className='form-control'
                    defaultValue={partnerDetails.partnerDescription}
                    onChange={(e) => detailsUpdate(e, 'partnerDescription')}></textarea>
                </div>

                <div className="p-t-20">
                  <Link to="/dashboard/Home" className="btn btn--radius bg-gradient-dark mr-4">Go Back</Link>
                  <button className="btn btn--radius btn--green" type="submit">Register</button>
                </div>
              </form>
            </div>
          </div>
        </div >
      </div >

    </>
  )
}