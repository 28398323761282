import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Datatablefile } from '../../Vendor/Datatablefile'
import { GET_PLATFORM_USERS } from '../../config/device_functions'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export const PlatformUsers = () => {
  const [otherLogsdata, setotherLogsdata] = useState([])
  const [userCount, SetuserCount] = useState(0)

  const DateRange = useSelector((state) => state.filter.DateRange)
  const dataFilters = useSelector((state) => state.filter.FilteredItems)

  const loginTypeval = useSelector((state) => state.AuthSlice.loginType)
  const PartnerId = useSelector((state) => state.AuthSlice.partnerId)


  const LoadContent = (pagenum = {
    current: 1,
    pageSize: 20,
  }) => {
    const body = {
      "filter": {
        "startDate": DateRange?.startDate,
        "endDate": DateRange?.endDate,
      }
    }

    if (loginTypeval === 'partner') {
      body.filter.partnerId = PartnerId
    }

    if (dataFilters && dataFilters?.selectedPartnerId) {
      body.filter.partnerId = dataFilters?.selectedPartnerId[0]
    }

    if (dataFilters && dataFilters?.selectedDeviceId) {
      body.filter.deviceId = dataFilters?.selectedDeviceId
    }

    GET_PLATFORM_USERS(body)
      .then((resp) => {
        if (resp?.status === 200) {
          setotherLogsdata(resp?.data.data)
          SetuserCount(resp?.data.userCount)
        }
      })
    .catch((err) => console.log("Data not found Msg :", err))
  }

  useEffect(() => {
    LoadContent()
  }, [DateRange, dataFilters])


  return (
    <>
      <div className="content-wrapper">
        <div className="info-box mb-3 mx-3 w-50">
          <span className="info-box-icon bg-danger elevation-1">
            <FontAwesomeIcon icon={faUsers} />
          </span>
          <div className="info-box-content">
            <span className="info-box-text">Platform Total Users</span>
            <span className="info-box-number">Date Range : {DateRange?.startDate} to {DateRange?.endDate}</span>
            <span className="info-box-number">Users : {userCount}</span>
          </div>

        </div>

      </div>
    </>

  )
}
