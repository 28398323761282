import React, { useEffect, useState } from 'react';
import { Form, Select, Space } from 'antd';
import { MultiSelect } from "react-multi-select-component";

export const MuiltipleSelectPicker = ({ callbackOnchange, data }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setoptions] = useState([]);

  useEffect(() => {
    let optionsVal = [];
    data?.values?.forEach((obj,index) => {
      optionsVal.push({
        value: obj,
        key: index,
        label: obj,
      });
    });
    setoptions(optionsVal)
  }, [data]);

  useEffect(() => {
    if (selectedOptions.length) {
      const selectedKeys = selectedOptions?.map((item) => {
        return item.value
      })
      console.log(selectedKeys)
      callbackOnchange({ 'selectedValues': selectedKeys });
    }
    else {
      callbackOnchange({ 'selectedValues': [] });
    }
  }, [selectedOptions])

  return (
    <>
      <Form.Item className='w-100'
        label="">
        <p className='filterSubject'>Filter Subject</p>

        <MultiSelect
          className='w-100'
          options={options}
          value={selectedOptions}
          onChange={setSelectedOptions}
        />
      </Form.Item>
    </>
  );
};
