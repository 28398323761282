import React, { useEffect, useState } from 'react';
import { VerticalBarchart } from '../../../Vendor/charts/VerticalBarChart';
import { useSelector } from 'react-redux';
import { GET_MUSIC_EVENTS } from '../../../config/events_functions';

export const Chart_Graph = () => {
  const DateRange = useSelector((state) => state.filter.DateRange);
  const [playData, setplayData] = useState([]);
  const [clickData, setclickData] = useState([]);
  const [viewallData, setviewallData] = useState([]);


  useEffect(() => {
    const body = {
      pageNo: 1,
      pageSize: 25,
      filter: {
        startDate: DateRange?.startDate,
        endDate: DateRange?.endDate,
        eventLogs: ["play", "click", "viewall"],
        groupBy: "month",
        year: 2024,
      },
    };

    GET_MUSIC_EVENTS(body)
      .then((resp) => {
        if (resp?.status === 200) {
          console.log(resp);
          let playArr = []
          let clickArr = []
          let viewallArr = []

          resp?.data?.data?.forEach((arrObj) => {
            return arrObj.forEach((objVal) => {
              if (objVal.event === 'play') {
                playArr.push(objVal.event_count);
              } else if (objVal.event === 'click' || objVal.event === 'Click') {
                clickArr.push(objVal.event_count);
              } else if (objVal.event === 'viewall') {
                viewallArr.push(objVal.event_count);
              }
            });
          })

          setplayData(playArr)
          setclickData(clickArr)
          setviewallData(viewallArr)
        }
      })
      .catch((err) => console.log("Data not found Msg :", err));
  }, [DateRange]);

  const chartData = {
    title: "Music Logs",
    playValues: playData,
    viewAllValues: viewallData,
    clickValues: clickData,
  };

  return (
    <div className='row dashboard-content'>
      <div className='col-11 col-sm-10 ml-sm-3 ml-2'>
        <VerticalBarchart data={chartData} />
      </div>
    </div>
  );
};

