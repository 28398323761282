import React, { useEffect, useState } from 'react';
import { Select, Space } from 'antd';

const SelectPicker = ({ callbackOnchange, ...props }) => {
    const [options, setoptions] = useState([])
    const [size, setSize] = useState('middle');
    const [listType, setlistType] = useState(props?.data?.Listtype)
    const [defaultselected, setDefaultselected] = useState(props?.data?.defaultSelected)

    const handleChange = (value) => {
        let selectedValues = []
        selectedValues.push(value)
        setDefaultselected([])
        callbackOnchange({ 'listType': listType, 'selectedValue': selectedValues })
    };

    useEffect(() => {
        let optionsVal = []
        if (listType === 'partner') {
            props?.data?.selectMenu?.forEach((obj) => {
                optionsVal.push({
                    value: obj?.partnerId,
                    key: obj?.partnerId,
                    label: obj?.partnerName,
                });
            })
            setoptions(optionsVal)
        }
        else if (listType === 'device') {
            props?.data?.selectMenu?.forEach((obj) => {
                optionsVal.push({
                    value: obj?.deviceId,
                    key: obj?.deviceId,
                    label: obj?.deviceName,
                });
            })
            setoptions(optionsVal)
        }
    }, [props?.data])

    return (
        <>
            <Space
                direction="vertical"
                style={{
                    width: '100%',
                }}
            >
                <Select
                    mode={listType === 'device' ? 'multiple' : ''}
                    size={size}
                    placeholder="Please select"
                    defaultValue={props.data.defaultSelected && props.data.defaultSelected.length ? defaultselected : []}
                    onChange={handleChange}
                    style={{
                        width: '100%',
                    }}
                    options={options}
                />
            </Space>
        </>
    );
};
export default SelectPicker;