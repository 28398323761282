import { ForgotPassword, LoginUser, Password_OTPVerify, UpdatePassword } from "./apiEndpoints"
import { GetAPI, PostAPI } from "./apiMethods"

export const Login_Me = async (Payload) => {

    return PostAPI(LoginUser,Payload)
        .then((data) => {
            return data?.data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
}


export const SentPasswordMail = async (Payload) => {
    return PostAPI(ForgotPassword,Payload)
        .then((data) => {
            return data?.data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
}

export const ForgotPass_VerifyOTP = async (Payload) => {
    return PostAPI(Password_OTPVerify,Payload)
        .then((data) => {
            return data?.data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
}

export const updateMypassword = async (Payload) => {
    return PostAPI(UpdatePassword,Payload)
        .then((data) => {
            return data?.data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
}








// export const Update_Profile = (async(RequestedData)=>{
//     console.log(RequestedData)
//     const formData = new FormData()
//     if(RequestedData != undefined)
//     {
//         formData.append('supervisorImage',RequestedData?.supervisorImage)
//         formData.append('ImageName',RequestedData?.ImageName)
//     }
//     formData.append('name',RequestedData?.name)
//     formData.append('email',RequestedData?.email)
//     formData.append('phone',RequestedData?.phone)
//     formData.append('supervisorId',1)

//     return PostAPI(UPDATEPROFILE,formData).then((data)=>{
//         console.log(data)
//         return data
//     })
//     .catch((error)=>{
//         console.log(error)
//         return error
//     })
// })

// export const AssignBooking_toDeliveryGuy = (async(RequestedData)=>{
//     return PostAPI(AssignBooking,RequestedData).then((data)=>{
//         console.log(data)
//         return data
//     })
//     .catch((error)=>{
//         console.log(error)
//         return error
//     })
// })