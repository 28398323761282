import React, { useEffect, useState } from 'react';
import { Button, Drawer, Space } from 'antd';
import SelectPicker from './selectPicker';
import { GET_REGISTERED_DEVICES, GET_REGISTERED_PARTNERS } from '../config/device_functions';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alldevicesList } from '../Redux/reducers/eventlogSlice';
import { RemoveFilterData, dataFilter } from '../Redux/reducers/filterSlice';


const SidebarDrawer = () => {
    const [open, setOpen] = useState(false);
    const [size, setSize] = useState();
    const [partnerList, setpartnerList] = useState([])
    const [Devices, SetDevices] = useState([])
    const [selectedPartnerId, setselectedPartnerId] = useState([])
    const [selectedDeviceId, setselectedDeviceId] = useState([])
    const [DefaultSelectedPartner, setDefaultSelectedPartner] = useState([])
    const [DefaultSelectedDevice, setDefaultSelectedDevice] = useState([])

    const loginTypeval = useSelector((state) => state.AuthSlice.loginType)
    const PartnerId = useSelector((state) => state.AuthSlice.partnerId)
    const dataFilters = useSelector((state) => state.filter.FilteredItems)

    const dispatch = useDispatch()

    const showDefaultDrawer = () => {
        setSize('default');
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const applyFilter = () => {
        let reduxObj = {}

        selectedDeviceId?.length && (reduxObj.selectedDeviceId = selectedDeviceId)
        if (loginTypeval === 'admin') {
            reduxObj.selectedPartnerId = selectedPartnerId?.length ? selectedPartnerId : PartnerId
        }
        Object.keys(reduxObj)?.length && dispatch(dataFilter(reduxObj))
        setOpen(false);
    }

    useEffect(() => {
        console.log(dataFilters)
        console.log(Object.keys(dataFilters).length)
        if (Object.keys(dataFilters).length !== 0){
            console.log(dataFilters?.selectedPartnerId[0])
            if (dataFilters?.selectedPartnerId) {
                getDeviceList(dataFilters?.selectedPartnerId[0], 'partner')
            }
            setDefaultSelectedPartner(dataFilters?.selectedPartnerId)
            setDefaultSelectedDevice(dataFilters?.selectedDeviceId)
        }
        else{
            console.log("else condition")
            setDefaultSelectedPartner([])
            setDefaultSelectedDevice([])
        }
    }, [dataFilters])


    // Load device list
    const getDeviceList = (selectorPartnerId = '', loginTypeval) => {
        console.log(selectorPartnerId, loginTypeval)
        GET_REGISTERED_DEVICES(selectorPartnerId, loginTypeval)
            .then((resp) => {
                if (resp?.status === 200) {
                    dispatch(alldevicesList(resp?.data?.data))
                    SetDevices(resp?.data?.data)
                }
            })
            .catch((err) => console.log("Something went wrong :", err))
    }

    useEffect(() => {
        // Load partner list
        open && GET_REGISTERED_PARTNERS()
            .then((resp) => {
                console.log(resp)
                if (resp?.status === 200) {
                    setpartnerList(resp?.data?.data)
                    // getDeviceList('', 'admin')
                }
                else {
                    toast.error("Partner list not found", {
                        position: "top-right"
                    });
                }
            })
            .catch((err) => console.log("Something went wrong :", err))

        if (open && loginTypeval === 'partner') {
            getDeviceList(PartnerId, loginTypeval)
        }
    }, [open])

    const selectedList = (data) => {
        if (data?.listType === 'partner') {
            getDeviceList(data?.selectedValue[0], 'partner')
            setselectedPartnerId(data?.selectedValue)
        }
        if (data?.listType === 'device') {
            setselectedDeviceId(data?.selectedValue[0])
        }
    }

    const RemoveFilter = () => {
        dispatch(RemoveFilterData())
    }

    return (
        <>
            <ToastContainer />
            <button className='d-inline-flex align-items-center header-btn btn btn-secondary mr-2' onClick={showDefaultDrawer}>
                <FontAwesomeIcon icon={faFilter} className='mr-2' />
                Filter
            </button>
            {
                Object.keys(dataFilters)?.length !== 0 && <><p className='text-primary mr-2 ' role="button" onClick={RemoveFilter}>Remove Filter </p>
                    {/* <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom fdfdf dfd dfd df dfd dfd">
                        <FontAwesomeIcon icon={faCircleInfo} />
                    </button> */}
                </>

            }
            <Drawer
                title={`Data Filters`}
                placement="right"
                size={size}
                onClose={onClose}
                open={open}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="primary" onClick={applyFilter}>
                            Apply
                        </Button>
                    </Space>
                }>

                {
                    loginTypeval === 'admin' &&
                    <div>
                        <label>Select Partner</label>
                        <SelectPicker
                            data={{ 'selectMenu': partnerList, 'Listtype': 'partner', 'defaultSelected': DefaultSelectedPartner }}
                            callbackOnchange={selectedList} />
                    </div>
                }

                <div className='mt-3'>
                    <label>Select Devices</label>
                    <SelectPicker
                        data={{ 'selectMenu': Devices, 'Listtype': 'device', 'defaultSelected': DefaultSelectedDevice }}
                        callbackOnchange={selectedList} />
                </div>
            </Drawer>
        </>
    );
};
export default SidebarDrawer;