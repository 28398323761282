import React, { useEffect, useState } from 'react'
import { Logs } from './Logs'
import { Chart_Graph } from './Chart_Graph'

export const TvshowsLogs = () => {
  const [SelectedType, setSelectedType] = useState('logs')
  const updateType = (type) => {
    setSelectedType(type)
  }
  const isLogsSelected = SelectedType === 'logs';

  return (
    <div className="wrapper dashboard-bg">
      {/* <div className="preloader flex-column justify-content-center align-items-center">
        <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60" />
      </div> */}
      <section className='container-head my-3 dashboard-content'>
        <div className='d-flex ml-4'>
          <button className={`btn ${isLogsSelected ? 'btn-primary' : 'btn-secondary'} mr-3`} onClick={() => updateType('logs')}>Logs</button>
          <button className={`btn ${isLogsSelected ? 'btn-secondary' : 'btn-primary'}`} onClick={() => updateType('chart')}>Chart</button>
        </div>
      </section>
      {SelectedType === 'logs' ? (
        <Logs />
      ) : (
        <Chart_Graph />
      )}
    </div>
  )
}
