// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DatepickerBG{
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.8);
    z-index: 1;
    top: 0;
    left: 0;
}

.picker-container{
    background: #fff;
    z-index: 1;
    width: 45%;
    margin: 0 auto;
    position: absolute;
    left: 30%;
    border-radius: 5px;
    top: 8%;
    padding: 2%;
}
.rdrDefinedRangesWrapper {
    width: 177px;
}
/* media query///////////// */
@media screen and (max-width:400px)
{
    .rdrDefinedRangesWrapper {
        width: 100px;
    }
    .picker-container {
        width: 96%;
        left: 2%;
    }
    .rdrCalendarWrapper {
        font-size: 8px;
    }
}

/* .picker-container {
    width: 50%;
} */`, "",{"version":3,"sources":["webpack://./src/Vendor/Rangepicker/index.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,2BAA2B;IAC3B,UAAU;IACV,MAAM;IACN,OAAO;AACX;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,UAAU;IACV,cAAc;IACd,kBAAkB;IAClB,SAAS;IACT,kBAAkB;IAClB,OAAO;IACP,WAAW;AACf;AACA;IACI,YAAY;AAChB;AACA,6BAA6B;AAC7B;;IAEI;QACI,YAAY;IAChB;IACA;QACI,UAAU;QACV,QAAQ;IACZ;IACA;QACI,cAAc;IAClB;AACJ;;AAEA;;GAEG","sourcesContent":[".DatepickerBG{\n    position: absolute;\n    width: 100%;\n    height: 100vh;\n    background: rgba(0,0,0,0.8);\n    z-index: 1;\n    top: 0;\n    left: 0;\n}\n\n.picker-container{\n    background: #fff;\n    z-index: 1;\n    width: 45%;\n    margin: 0 auto;\n    position: absolute;\n    left: 30%;\n    border-radius: 5px;\n    top: 8%;\n    padding: 2%;\n}\n.rdrDefinedRangesWrapper {\n    width: 177px;\n}\n/* media query///////////// */\n@media screen and (max-width:400px)\n{\n    .rdrDefinedRangesWrapper {\n        width: 100px;\n    }\n    .picker-container {\n        width: 96%;\n        left: 2%;\n    }\n    .rdrCalendarWrapper {\n        font-size: 8px;\n    }\n}\n\n/* .picker-container {\n    width: 50%;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
