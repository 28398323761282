import React, { useEffect, useState } from 'react';
import { DatePicker, Popconfirm, Table, Tag } from 'antd';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Spinner } from '../../Vendor/Spinner';
import { DELETE_A_PAPER } from '../../config/newspaper/news_functions';

export const Datatable = (props) => {
  const urlParams = useLocation()

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [propsData, setPropsData] = useState([]);
  const [tableData, settableData] = useState()
  const [pathname, setpathname] = useState(urlParams?.pathname)
  const [EditRowData, setEditRowData] = useState([])
  const [viewdetailsRow, setviewdetailsRow] = useState([])
  const [partnerList, setpartnerList] = useState([])

  useEffect(() => {
    setPropsData(props?.data);
    settableData(props?.data?.tableData)
  }, [props]);

  useEffect(() => {
    console.log(viewdetailsRow)
  }, [viewdetailsRow])

  const { titles, dataIndexes } = propsData;

  const columns = titles && dataIndexes ? titles.map((title, index) => {
    if (title == 'Status') {
      return {
        title,
        dataIndex: dataIndexes[index],
        render: (_, { status }) => (
          <Tag color={status == 1 ? 'green' : 'red'} key={status}>
            {status == 1 ? 'active' : 'inactive'}
          </Tag>
        )
      };
    }
    else if (title == 'File') {
      return {
        title,
        dataIndex: dataIndexes[index],
        render: (_, record) => (
          <>
            <span className='text-primary mr-2 link-btn' data-toggle="modal" data-target="#Viewalldetails" onClick={() => setviewdetailsRow(record)}>View</span>
            <span className='text-primary mr-2 link-btn'>
              <a
                href={record.path}
                download="Newspaper"
                target="_blank"
                rel="noreferrer"
                className='text-decoration-none'
              >Download</a>
            </span>

          </>
        )
      };
    }
    else {
      return {
        title,
        dataIndex: dataIndexes[index],
      };
    }
  }) : [];


  if (pathname === '/dashboard/NewsPaperListing') {
    columns.push({
      title: 'Action',
      dataIndex: 'Action',
      render: (_, record) =>
        <>
          {/* <span className='text-primary mr-2 link-btn' data-toggle="modal" data-target="#EditListing" onClick={() => setEditRowData(record)}>Edit</span> */}

          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
            <span className='text-primary link-btn'>Delete</span>
          </Popconfirm>
        </>
    })
  }

  const handleDelete = (key) => {
    DELETE_A_PAPER(key).then((data) => {
      if (data?.code === 200) {
        const newData = tableData?.filter((item) => item.id !== key);
        settableData(newData);
      }
      else {
        toast.error("Something went wrong / Data not found ", {
          position: "top-right"
        });
      }

    }).catch((error) => {
      console.log(error)
    })
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  ;
  const UpdateEntryData = (type, e) => {
    setEditRowData({ ...EditRowData, [type]: e.target.value });
  }

  // const saveUpdatedChanges = () => {
  //   console.log(EditRowData)
  //   UPDATE_A_DEVICE(EditRowData).then((res) => {
  //     console.log(res)
  //     props.callbackLoadDevices()
  //   }
  //   ).catch((error) => console.log(error))
  // }

  return (
    <div className="content-wrapper">
      <ToastContainer />

      <section className="content dataTableContent">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title" dangerouslySetInnerHTML={{ __html: propsData?.heading }}></h3>
                </div>
                <div className="card-body">
                  {
                    propsData?.spinnerVisible === true ?
                      <Spinner />
                      : <Table columns={columns} dataSource={tableData?.map((item) => ({ ...item, 'key': item.id }))} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* //View all details Modal */}
      <div className="modal fade" id="Viewalldetails" tabIndex="-1" role="dialog" aria-labelledby="ViewalldetailsTitle" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="viewModal">View all details</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

              <div className="viewadetails p-3 mb-3">

                <div className="row mb-3">
                  <div className="col-3">
                    <h5>{viewdetailsRow?.name}</h5>
                    <p className="">Created At {viewdetailsRow?.createdAt}</p>
                  </div>
                </div>

                <div className="row">

                  <div className="table-responsive">
                    <table className="table">
                      <tr>
                        <th>Paper-Name</th>
                        <td>{viewdetailsRow?.name}</td>

                        <th>Language</th>
                        <td>{viewdetailsRow?.language}</td>
                      </tr>
                      <tr>
                        <th>Paper Date</th>
                        <td>{viewdetailsRow?.paper_date}</td>

                        <th>Format</th>
                        <td>{viewdetailsRow?.file_format}</td>
                      </tr>
                      <tr>
                        <th>File Size</th>
                        <td>{viewdetailsRow?.size}</td>

                        <th>Status</th>
                        <td>{viewdetailsRow?.status}</td>
                      </tr>
                    </table>
                  </div>

                </div>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>


              {/* //Edit Modal */}
              <div className="modal fade" id="EditListing" tabIndex="-1" role="dialog" aria-labelledby="EditListingTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="UpdateInformation">Update Information</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">

                      {pathname === '/dashboard/PartnerListing' ?
                        <> {/* // Partner Edit Form */}
                          <form>
                            <div className="card-body">
                              <div className="form-group">
                                <label htmlFor="partnerName">Partner Name</label>
                                <input type="text" className="form-control" id="partnerName" value={EditRowData?.partnerName} placeholder="Enter name" />
                              </div>

                              <div className="form-group">
                                <label>Status</label>
                                <select className='form-control' value={EditRowData?.partnerStatus}>
                                  <option value={0}>InActive</option>
                                  <option value={1}>Active</option>
                                </select>
                              </div>

                              <div className='row'>
                                <div className='col-6'>
                                  <div className="form-group">
                                    <label >Start Date</label>
                                    <DatePicker className="form-control"
                                      selected={EditRowData?.partnerStartDate}
                                      // onChange={(date) => setEndDate(date)} 
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select" />
                                  </div>
                                </div>

                                <div className='col-6'>
                                  <div className="form-group">
                                    <label >End Date</label>
                                    <DatePicker className="form-control"
                                      selected={EditRowData?.partnerEndDate}
                                      // onChange={(date) => setEndDate(date)} 
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select" />
                                  </div>
                                </div>
                              </div>

                            </div>
                          </form>
                        </>
                        : ''}

                      {pathname === '/dashboard/DeviceListing' ?
                        <> {/* // Device Edit Form */}
                          <form>
                            <div className="card-body">
                              <div className="form-group">
                                <label htmlFor="DeviceName">Device Name</label>
                                <input type="text" className="form-control" value={EditRowData?.deviceName} onChange={(e) => UpdateEntryData('deviceName', e)} id="DeviceName" placeholder="Enter name" />
                              </div>

                              <div className="form-group">
                                <label>Choose Partner Name / ID</label>
                                <select className='form-control' value={EditRowData?.partnerId} onChange={(e) => UpdateEntryData('partnerId', e)}>
                                  {
                                    partnerList?.map((obj) => {
                                      return <option key={obj.id} value={obj.partnerId}>{obj.partnerName} {'- ' + obj.partnerId}</option>
                                    })
                                  }
                                </select>
                              </div>

                              <div className="form-group">
                                <label>Status</label>
                                <select className='form-control' value={EditRowData?.deviceStatus} onChange={(e) => UpdateEntryData('deviceStatus', e)}>
                                  <option value={0}>InActive</option>
                                  <option value={1}>Active</option>
                                </select>
                              </div>

                            </div>
                          </form>
                        </>
                        : ''}

                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                      {/* <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={saveUpdatedChanges}>Save changes</button> */}
                      <button type="button" className="btn btn-primary" data-dismiss="modal" >Save changes</button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


