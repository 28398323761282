import { DELETE_ADS_DATA, GET_ALL_ADS_DATA, UPLOAD_AD } from "../apiEndpoints"
import { DeleteAPI, GetAPI, PostAPI } from "../apiMethods";

export const UPLOAD_YOUR_AD = (async (Payload) => {

    const formdata = new FormData();
    formdata.append("file", Payload['file']);
    formdata.append("data", JSON.stringify(Payload.data))
    let multipartFormDataAllow = true;

    return PostAPI(UPLOAD_AD, formdata, multipartFormDataAllow)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_ADS_LIST = (async () => {
    return GetAPI(GET_ALL_ADS_DATA)
        .then((data) => {
            return data?.data
        })
        .catch((error) => {
            console.log(error);
            return error;
        })
})

// Delete an ads
export const DELETE_A_ADVERTISE = (async (AdID) => {
    return DeleteAPI(DELETE_ADS_DATA + '?ad_id=' + AdID)
        .then((data) => {
            return data?.data
        }).catch((error) => {
            console.log(error)
            return error
        })
})
