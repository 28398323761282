import React, { useEffect, useState } from 'react'
import { Datatablefile } from '../../../Vendor/Datatablefile'
import { GET_REGISTERED_PARTNERS } from '../../../config/device_functions'

export const PartnerListing = () => {

  const [Partners, SetPartners] = useState([])

  const tabledata = {
    type: 'partner',
    heading: `<b>Total : </b>${Partners?.length}`,
    tableData: Partners,
    spinnerVisible: false,
    titles: ['Id', 'PartnerId', 'Partner Name', 'Status', 'StartDate', 'EndDate', 'Language', 'Register Time'],
    dataIndexes: ['id', 'partnerId', 'partnerName', 'partnerStatus', 'partnerStartDate', 'partnerEndDate', 'partnerLanguage', 'createdAt']
  }

  useEffect(() => {
    tabledata["spinnerVisible"] = true
    GET_REGISTERED_PARTNERS()
      .then((resp) => {
        if (resp?.status === 200) {
          SetPartners(resp?.data?.data)
          tabledata["spinnerVisible"] = false
        }
      })
      .catch((err) => console.log("Data not found Msg :", err))
  }, [])

  return (
    <div className="wrapper dashboard-bg">
      <Datatablefile data={tabledata} />
    </div>
  )
}
