import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import eventlogsReducer from './reducers/eventlogSlice';
// import logger from 'redux-logger'
import filterReducer from './reducers/filterSlice';
import AuthSliceReducers from './reducers/authSlice';


const rootReducer = combineReducers({
  eventlogs: eventlogsReducer,
  filter: filterReducer,
  AuthSlice : AuthSliceReducers,
})


const persistConfig = {
  key: 'root',
  storage,
  reducers: rootReducer
}

const persistedReducer = persistReducer(persistConfig,rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export const persistor = persistStore(store);
