import React from 'react'
import DataInfoDrawer from '../../../Vendor/DataInfoDrawer'
import { AnalyticsHome } from './AnalyticsHome'
import { useSelector } from 'react-redux'
import { AdvertiseHome } from './AdvertiseHome'
import { NewspaperHome } from './NewspaperHome'
import { MagazineHome } from './MagazineHome'

export const DashboardHome = () => {
    const loginData = useSelector((state) => state.AuthSlice.loginData)
    console.log(loginData)

    return (
        <>
            <div className="content-wrapper">
                <section className="content-header">
                    {
                        loginData?.type === "advertise_admin" ?
                            <AdvertiseHome /> : loginData?.type === "news_admin" ?
                                <NewspaperHome /> : loginData?.type === "magazine_admin" ?
                                <MagazineHome/> :
                                <AnalyticsHome />
                    }
                </section>
            </div>
        </>
    )
}
