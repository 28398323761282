import React, { useEffect, useState } from 'react'
import { Datatablefile } from '../../../Vendor/Datatablefile'
import { GET_FREQUENTFLYERS_EVENTS } from '../../../config/form_functions'

export const FrequentFlyers = () => {

  const [frequentFlyerData, setfrequentFlyerData] = useState([])

  const tabledata = {
    type: 'frequent flyer',
    heading: `<b>Total : </b>${frequentFlyerData?.length}`,
    tableData: frequentFlyerData,
    spinnerVisible: false,
    titles: ['Id', 'Device', 'Mobile', 'Name', 'Email', 'Preferences', 'Synctime'],
    dataIndexes: ['id', 'deviceId', 'contact_details', 'flyer_name', 'email_id', 'communication_preferences', 'syncDatetime']
  }
  
  useEffect(() => {
    tabledata["spinnerVisible"] = true
    GET_FREQUENTFLYERS_EVENTS()
      .then((resp) => {
        if (resp?.data?.status === true) {
          setfrequentFlyerData(resp?.data?.data)
          tabledata["spinnerVisible"] = false
        }
      })
      .catch((err) => console.log("Data not found Msg :", err))
  }, [])


  return (
    <div className="wrapper dashboard-bg">
      {/* <div className="preloader flex-column justify-content-center align-items-center">
        <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60" />
      </div> */}
      <Datatablefile data={tabledata} />
    </div>
  )
}

