import { GET_GAMES, GET_MOVIES, GET_MUSIC, GET_OTHERLOGS, GET_TVSHOWS } from "./apiEndpoints"
import { GetAPI, PostAPI } from "./apiMethods"

// Event logs api methods ////////////////////////////////////////////////////////////
export const GET_MOVIES_EVENTS = (async (body) => {
    return PostAPI(GET_MOVIES, body).then((data) => {
        return data
    })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_MUSIC_EVENTS = (async (body) => {
    return PostAPI(GET_MUSIC, body).then((data) => {
        return data
    })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_TVSHOWS_EVENTS = (async (body) => {
    return PostAPI(GET_TVSHOWS, body).then((data) => {
        console.log(data)
        return data
    })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_GAMES_EVENTS = (async (body) => {
    return PostAPI(GET_GAMES, body).then((data) => {
        console.log(data)
        return data
    })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_OTHERS_EVENTS = (async (body) => {
    return PostAPI(GET_OTHERLOGS, body).then((data) => {
        console.log(data)
        return data
    })
        .catch((error) => {
            console.log(error)
            return error
        })
})

