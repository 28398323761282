import React from 'react'
import { RotatingLines } from 'react-loader-spinner'

const spinnerColor = {
    color: '#1C2B41'
}

export const Spinner = () => {
    return (

        <div style={spinnerColor} className='loading-spinner d-grid justify-content-center loading-spinner'>
            <RotatingLines
                visible={true}
                height="50"
                width="50"
                strokeWidth="5"
                strokeColor="#1C2B41"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
            /> <span>Loading data....</span>
        </div>


    )
}
