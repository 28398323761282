import React, { useEffect, useState } from 'react'
import { Datatablefile } from '../../../Vendor/Datatablefile'
import { useSelector } from 'react-redux'
import { GET_GAMES_EVENTS } from '../../../config/events_functions'

export const Logs = () => {
  const [gamesData, setgamesData] = useState([])
  const [Pagination, setPagination] = useState({});
  const [PaginationTotalCount, setPaginationTotalCount] = useState({});

  const DateRange = useSelector((state) => state.filter.DateRange)
  const dataFilters = useSelector((state) => state.filter.FilteredItems)

  const loginTypeval = useSelector((state) => state.AuthSlice.loginType)
  const PartnerId = useSelector((state) => state.AuthSlice.partnerId)

  const tabledata = {
    type: 'games',
    heading: `Total : ${gamesData?.length}`,
    tableData: gamesData,
    spinnerVisible: false,
    titles: ['Id', 'Event', 'DeviceId', 'Name', 'Source', 'Destination', 'Subject', 'Device IP', 'Synctime'],
    dataIndexes: ['id', 'event', 'deviceId', 'name', 'source','destination', 'subject', 'ip', 'syncDatetime'],
    paginationDetails: PaginationTotalCount
  }

  const LoadContent = (pagenum = {
    current: 1,
    pageSize: 20,
  }) => {
    const body = {
      "pageNo": pagenum?.current,
      "pageSize": pagenum?.pageSize,
      "filter": {
        "startDate": DateRange?.startDate,
        "endDate": DateRange?.endDate,
      }
    }

    if (loginTypeval === 'partner') {
      body.filter.partnerId = PartnerId
    }

    if (dataFilters && dataFilters?.selectedPartnerId) {
      body.filter.partnerId = dataFilters?.selectedPartnerId[0]
    }

    if (dataFilters && dataFilters?.selectedDeviceId) {
      body.filter.deviceId = dataFilters?.selectedDeviceId
    }

    tabledata["spinnerVisible"] = true
    GET_GAMES_EVENTS(body)
      .then((resp) => {
        if (resp?.status === 200) {
          setgamesData(resp?.data?.data)
          tabledata["spinnerVisible"] = false

          setPaginationTotalCount(resp?.data?.totaldata)

        }
      })
      .catch((err) => console.log("Data not found Msg :", err))
  }


  useEffect(() => {
    LoadContent()
  }, [DateRange, dataFilters])

  const paginationObj = (data) => {
    setPagination(data); // Wait for the state update to complete
  };

  useEffect(() => {
    if (Object.keys(Pagination).length) {
      LoadContent(Pagination);
    }
  }, [Pagination])

  return (
    <Datatablefile data={tabledata} paginationCallback={paginationObj} />
  )
}
