import { GET_CABBOOKINGS, GET_CABINDEFECT, GET_FEEDBACK, GET_FREQUENTFLYERS, GET_HOTELBOOKINGS, GET_SHOPPING, GET_SURVEYFEEDBACK } from "./apiEndpoints"
import { GetAPI, PostAPI } from "./apiMethods"

// forms post api's /////////////////////////////////////////////////////////////////
export const GET_CABBOOKINGS_EVENTS = (async () => {
    const body = {
        "pageNo": 1,
        "pageSize": 25,
        "filter": {
            "startDate": "2023-01-13",
            "endDate": "2026-03-25"
        }
    }
    return PostAPI(GET_CABBOOKINGS, body).then((data) => {
        console.log(data)
        return data
    })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_HOTELBOOKINGS_EVENTS = (async () => {
    const body = {
        "pageNo": 1,
        "pageSize": 25,
        "filter": {
            "startDate": "2023-01-13",
            "endDate": "2026-03-25"
        }
    }
    return PostAPI(GET_HOTELBOOKINGS, body).then((data) => {
        console.log(data)
        return data
    })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_CABINDEFECT_EVENTS = (async () => {
    const body = {
        "pageNo": 1,
        "pageSize": 25,
        "filter": {
            "startDate": "2023-01-13",
            "endDate": "2026-03-25"
        }
    }
    return PostAPI(GET_CABINDEFECT, body).then((data) => {
        console.log(data)
        return data
    })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_FEEDBACK_EVENTS = (async () => {
    const body = {
        "pageNo": 1,
        "pageSize": 25,
        "filter": {
            "startDate": "2023-01-13",
            "endDate": "2026-03-25"
        }
    }
    return PostAPI(GET_FEEDBACK, body).then((data) => {
        console.log(data)
        return data
    })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_SURVEYFEEDBACK_EVENTS = (async (body) => {
    return PostAPI(GET_SURVEYFEEDBACK, body).then((data) => {
        console.log(data)
        return data
    })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_SHOPPING_EVENTS = (async () => {
    const body = {
        "pageNo": 1,
        "pageSize": 25,
        "filter": {
            "startDate": "2023-01-13",
            "endDate": "2026-03-25"
        }
    }
    return PostAPI(GET_SHOPPING, body).then((data) => {
        console.log(data)
        return data
    })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_FREQUENTFLYERS_EVENTS = (async () => {
    const body = {
        "pageNo": 1,
        "pageSize": 25,
        "filter": {
            "startDate": "2023-01-13",
            "endDate": "2026-03-25"
        }
    }
    return PostAPI(GET_FREQUENTFLYERS, body).then((data) => {
        console.log(data)
        return data
    })
        .catch((error) => {
            console.log(error)
            return error
        })
})

