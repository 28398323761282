import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../../assets/images/logo_aerohub.png'
import { faVideo, faTv, faMusic, faGamepad, faList, faCommentDots, faBook, faTaxi, faHotel, faPenToSquare, faMobileScreen, faHandshake, faHouse, faBookOpenReader, faXmark, faUsers } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

export const Sidebar = () => {

    const [pageroute, setpageroute] = useState(window.location.pathname)
    const selector = useSelector((state) => state.AuthSlice.userAccess)
    const [menuOpen, setmenuOpen] = useState(false)
    const [userRights, setuserRights] = useState(false)

    const location = useLocation()

    useEffect(() => {
        console.log(location.pathname)
        setpageroute(location.pathname)
    }, [location.pathname])

    useEffect(() => {
        let parsedData = selector;
        if (typeof (selector) === 'string') {
            parsedData = JSON.parse(selector);
        }
        setuserRights(parsedData?.Leftsidebar)
    }, [])

    return (
        <>
            <aside className="main-sidebar elevation-4">
                <FontAwesomeIcon icon={faXmark} className='closeMark' id='closeMark' onClick={() => window.toggleBodyClass()} />
                {/* <aside className="main-sidebar sidebar-dark-primary elevation-4"> */}

                <Link to="/dashboard/Home" className="brand-link">
                    <img src={logo} alt="Aerohub Logo" className="brand-image"
                        style={{ "opacity": ".8" }} />
                    {/* <span className="brand-text">Aerohub</span> */}
                </Link>

                <div className="sidebar">
                    <div className="form-inline">
                        {/* <div className="input-group" data-widget="sidebar-search">
                            <input className="form-control form-control-sidebar" type="search" placeholder="Search"
                                aria-label="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-sidebar">
                                    <i className="fas fa-search fa-fw"></i>
                                </button>
                            </div>
                        </div> */}
                    </div>

                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">

                            {/* <li className="nav-item menu-open"> */}

                            <li className="nav-item">
                                <Link to='/dashboard/Home' className={`${pageroute === '/dashboard/Home' ? 'active-menu-item' : ''} nav-link`}>
                                    <FontAwesomeIcon icon={faHouse} />
                                    <p> Dashboard </p>
                                </Link>
                            </li>

                            {
                                userRights?.device_listing === 'show' ?
                                    <li className="nav-item">
                                        <Link to="/dashboard/DeviceListing" className={`${pageroute === '/dashboard/DeviceListing' ? 'active-menu-item' : ''} nav-link`}>
                                            <FontAwesomeIcon icon={faMobileScreen} />
                                            <p>Device Listing</p>
                                        </Link>
                                    </li> : ''}
                            {
                                userRights?.partner_listing === 'show' ?
                                    <li className="nav-item">
                                        <Link to="/dashboard/PartnerListing" className={`${pageroute === '/dashboard/PartnerListing' ? 'active-menu-item' : ''} nav-link`}>
                                            <FontAwesomeIcon icon={faHandshake} />
                                            <p>Partner Listing</p>
                                        </Link>
                                    </li> : ''}

                            {
                                userRights?.DataContentList === "show" ?
                                    <>
                                        <a className="nav-item nav-link dropdown-link position-relative formsMenu" style={{ marginLeft: "-7px" }} data-bs-toggle="collapse" href="#dataContent"
                                            role="button" aria-expanded={`${pageroute === '/dashboard/MoviesContent' || pageroute === '/dashboard/TvshowsContent'
                                                || pageroute === '/dashboard/MusicContent' || pageroute === '/dashboard/GamesContent' ? true : false}`} aria-controls="dataContent">
                                            <i className="nav-icon fas fa-folder"></i>
                                            <p>
                                                Data Content
                                                <i className="fas fa-angle-left right angleArrow" style={{ "top": "1.1rem", "fontSize": "1.3rem" }}></i>
                                            </p>
                                        </a>

                                        <div className={`collapse multi-collapse ${pageroute === '/dashboard/MoviesContent' || pageroute === '/dashboard/TvshowsContent'
                                            || pageroute === '/dashboard/MusicContent' || pageroute === '/dashboard/GamesContent' ? 'show' : ''}`} id="dataContent">
                                            <ul className="card-body collpaseMenuItem pt-2 list-unstyled">
                                                {
                                                    userRights?.DataContent?.movies === "show" ?
                                                        <li className="nav-item">
                                                            <Link to="/dashboard/MoviesContent" className={`${pageroute === '/dashboard/MoviesContent' ? 'active-menu-item' : ''} nav-link`}>
                                                                <FontAwesomeIcon icon={faVideo} />
                                                                <p>Movies</p>
                                                            </Link>
                                                        </li> : ''}
                                                {
                                                    userRights?.DataContent?.tvshows === "show" ?
                                                        <li className="nav-item">
                                                            <Link to="/dashboard/TvshowsContent" className={`${pageroute === '/dashboard/TvshowsContent' ? 'active-menu-item' : ''} nav-link`}>
                                                                <FontAwesomeIcon icon={faTv} />
                                                                <p>Tvshows</p>
                                                            </Link>
                                                        </li> : ''}
                                                {
                                                    userRights?.DataContent?.musics === "show" ?
                                                        <li className="nav-item">
                                                            <Link to="/dashboard/MusicContent" className={`${pageroute === '/dashboard/MusicContent' ? 'active-menu-item' : ''} nav-link`}>
                                                                <FontAwesomeIcon icon={faMusic} />
                                                                <p>Music </p>
                                                            </Link>
                                                        </li> : ''}
                                                {
                                                    userRights?.DataContent?.games === "show" ?
                                                        <li className="nav-item">
                                                            <Link to="/dashboard/GamesContent" className={`${pageroute === '/dashboard/GamesContent' ? 'active-menu-item' : ''} nav-link`}>
                                                                <FontAwesomeIcon icon={faGamepad} />
                                                                <p>Games</p>
                                                            </Link>
                                                        </li> : ''}
                                            </ul>
                                        </div>
                                    </>
                                    : ''
                            }


                            <li className="nav-item">
                                <Link to="/dashboard/user_connections" className={`${pageroute === '/dashboard/user_connections' ? 'active-menu-item' : ''} nav-link`}>
                                    <FontAwesomeIcon icon={faUsers} />
                                    <p>
                                        Platform Users
                                    </p>
                                </Link>
                            </li>

                            {
                                userRights?.movieslogs === 'show' ?
                                    <li className="nav-item">
                                        {/* <i className="nav-icon fas fa-th"></i> */}
                                        <Link to="/dashboard/MoviesLogs" className={`${pageroute === '/dashboard/MoviesLogs' ? 'active-menu-item' : ''} nav-link`}>
                                            <FontAwesomeIcon icon={faVideo} />
                                            <p>
                                                Movies Logs
                                            </p>
                                            {/* </a> */}
                                        </Link>
                                    </li> : ''
                            }

                            {
                                userRights?.tvShowslogs === 'show' ?
                                    <li className="nav-item">
                                        <Link to="/dashboard/TvshowsLogs" className={`${pageroute === '/dashboard/TvshowsLogs' ? 'active-menu-item' : ''} nav-link`}>
                                            <FontAwesomeIcon icon={faTv} />
                                            <p>
                                                TV Shows Logs
                                            </p>
                                        </Link>
                                    </li> : ''
                            }

                            {
                                userRights?.musiclogs === 'show' ?
                                    <li className="nav-item">
                                        <Link to="/dashboard/MusicLogs" className={`${pageroute === '/dashboard/MusicLogs' ? 'active-menu-item' : ''} nav-link`}>
                                            <FontAwesomeIcon icon={faMusic} />
                                            <p>
                                                Music Logs
                                            </p>
                                        </Link>
                                    </li> : ''
                            }

                            {
                                userRights?.gameslogs === 'show' ?
                                    <li className="nav-item">
                                        <Link to="/dashboard/GamesLogs" className={`${pageroute === '/dashboard/GamesLogs' ? 'active-menu-item' : ''} nav-link`}>
                                            <FontAwesomeIcon icon={faGamepad} />
                                            <p>
                                                Games Logs
                                            </p>
                                        </Link>
                                    </li> : ''
                            }
                            {
                                userRights?.otherlogs === 'show' ?
                                    <li className="nav-item">
                                        <Link to="/dashboard/otherLogs" className={`${pageroute === '/dashboard/otherLogs' ? 'active-menu-item' : ''} nav-link`}>
                                            <FontAwesomeIcon icon={faBookOpenReader} />
                                            <p>
                                                Other Logs
                                            </p>
                                        </Link>
                                    </li>
                                    : ''
                            }
                            {
                                userRights?.surveyFeedback === "show" ?
                                    <li className="nav-item">
                                        <Link to="/dashboard/SurveyFeedback" className={`${pageroute === '/dashboard/SurveyFeedback' ? 'active-menu-item' : ''} nav-link`}>
                                            <FontAwesomeIcon icon={faCommentDots} />
                                            <p>Survey Feedback</p>
                                        </Link>
                                    </li> : ''}
                        </ul >
                    </nav >

                </div >

            </aside >
        </>
    )
}
