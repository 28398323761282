import React, { useEffect, useState } from 'react';
import { Form, Select, Space } from 'antd';
import { MultiSelect } from "react-multi-select-component";

export const MuiltipleSelectPicker = ({ callbackOnchange, data }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setoptions] = useState([]);

  useEffect(() => {
    let optionsVal = [];
    data?.values?.forEach((obj) => {
      optionsVal.push({
        value: obj?.id,
        key: obj?.id,
        label: obj?.id + ' - ' + obj?.title,
        disabled: typeof (obj?.ad_id) === "number" && (obj?.ad_id) != 0 ? true : false, // Disable options based on selected property in data object
      });
    });
    setoptions(optionsVal)
  }, [data]);

  useEffect(() => {
    if (selectedOptions.length) {
      const selectedIds = selectedOptions?.map((item) => {
        return item.value
      })
      console.log(selectedIds)
      callbackOnchange({ 'selectedContentId': selectedIds });
    }
    else {
      callbackOnchange({ 'selectedContentId': [] });
    }
  }, [selectedOptions])

  return (
    <>
      <Form.Item className='w-100'
        label="Choose Content Id's"
        rules={[
          { required: true, message: 'Please select content id !', type: 'array' },
        ]}
      >
        <p className='disabledPara'>Disabled content Id already have an Ad</p>

        <MultiSelect
          className='w-100'
          options={options}
          value={selectedOptions}
          onChange={setSelectedOptions}
        />
      </Form.Item>
    </>
  );
};
