import React from 'react'
// import DataInfoDrawer from '../../../Vendor/DataInfoDrawer'

export const AnalyticsHome = () => {
    return (


        <div className="container-fluid">
            {/* <h4 className='mb-2'>Data analysis with chart / graphs ( Dummy data)</h4>
            <h4 className='mb-2'>Data Content Records</h4>
            <DataInfoDrawer /> */}
            <div className='m-auto mt-5 w-50'>
            <img width={'100%'} height={'100%'} src={require('../../../assets/images/analytics_dash.png')} alt="Analytics"/>
            </div>
        </div>

    )
}
