import React, { useEffect, useState } from 'react'
import { Datatable } from '../helpers/Datatable'
import { GET_MAGAZINE_LIST } from '../../config/magazine/magazine_functions'

export const MagazineListing = () => {

  const [MagazineData, setMagazineData] = useState([])

  useEffect(() => {
    tabledata["spinnerVisible"] = true
    GET_MAGAZINE_LIST().then((data) => {
      if (data?.status === true) {
        tabledata["spinnerVisible"] = false
        setMagazineData(data?.data)
      }
    })
      .catch((err) => {
        console.log(err)
      })
  }, [])


  const tabledata = {
    type: 'ads',
    heading: `<b>Total : </b>${MagazineData?.length}`,
    tableData: MagazineData,
    spinnerVisible: false,
    titles: ['Id', 'Paper-Name', 'Language', 'Date', 'Status', 'Format','File'],
    dataIndexes: ['id', 'name', 'language', 'magazine_date', 'status', 'file_format','path']
  }


  return (
      <section className="content-header">
        <div className="container-fluid">
          <Datatable data={tabledata} />
        </div>
      </section>
  )
}
