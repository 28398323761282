import React from 'react'

export const NewspaperHome = () => {
    return (


        <div className="container-fluid">
            {/* <h4 className='mb-2'>Data Content Records</h4> */}
            <div className='w-100'>
            <img width={'100%'} height={'100%'} src={require('../../../assets/images/newspaper/newspaper.jpeg')}/>
            </div>
        </div>

    )
}
