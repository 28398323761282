import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from "react-csv"
import { DatePicker, Popconfirm, Table, Tag, Switch, Modal } from 'antd';
import { useLocation } from 'react-router-dom';
import { DELETE_A_DEVICE, DELETE_A_PARTNER, GET_REGISTERED_PARTNERS, UPDATE_A_DEVICE } from '../config/device_functions';
import { ToastContainer, toast } from 'react-toastify';
import { Spinner } from './Spinner';
import { DELETE_A_CITY, UPDATE_A_GAMES, UPDATE_A_MOVIE, UPDATE_A_MUSIC, UPDATE_A_TVSHOWS } from '../config/content_function';
import { MuiltipleSelectPicker } from './MultipleSelect';

export const Datatablefile = (props) => {
  const { paginationCallback = () => { } } = props;
  const { callbackLoadFilteredLogs = () => { } } = props;
  const urlParams = useLocation()
  const toastRef = useRef(null);

  const [propsData, setPropsData] = useState([]);
  const [tableData, settableData] = useState()
  const [pathname, setpathname] = useState(urlParams?.pathname)
  const [EditRowData, setEditRowData] = useState([])
  const [partnerList, setpartnerList] = useState([])
  const [switchStates, setSwitchStates] = useState({});
  const [modal2Open, setModal2Open] = useState(false);
  const [statusUpdate, setstatusUpdate] = useState('')
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 20,
  });

  const handleChange = (id, status) => {
    setstatusUpdate({
      'id': id,
      'status': status
    })
    setModal2Open(true)
  };


  const updateContentstatus = (() => {
    // setSwitchStates((prevState) => ({
    //   ...prevState,
    //   [statusUpdate?.id]: !prevState[statusUpdate?.id], // Toggle the state of the switch with the given ID
    // }));
    setModal2Open(false)

    if (toastRef.current) {
      toast.dismiss(toastRef.current); // Dismiss the previous toast if it exists
    }

    let UpdatePayload = {
      'id': statusUpdate?.id,
      'status': statusUpdate?.status == 1 ? 0 : 1
    }
    let updateTableName = ''

    switch (pathname) {
      case '/dashboard/MoviesContent':
        updateTableName = UPDATE_A_MOVIE
        break;

      case '/dashboard/TvshowsContent':
        updateTableName = UPDATE_A_TVSHOWS
        break;

      case '/dashboard/MusicContent':
        updateTableName = UPDATE_A_MUSIC
        break;

      case '/dashboard/GamesContent':
        updateTableName = UPDATE_A_GAMES
        break;
    }

    updateTableName(UpdatePayload).then((res) => {
      if (res.status == 200) {
        props.reloadDataTable(true)
        toastRef.current = toast.success(res?.data.message, {
          position: "top-right"
        });
      }
    }
    ).catch((error) => {
      toastRef.current = toast.error(error, {
        position: "top-right"
      });
      console.log(error)
    })
  })

  const handleTableChange = (pagination) => {
    paginationCallback(pagination)
    setTableParams(
      pagination
    );

    // `PropsData` is useless since `pageSize` changed
    // if (pagination.pageSize !== tableParams.pageSize) {
    //   setPropsData([]);
    // }
  };


  useEffect(() => {
    setPropsData(props?.data);
    settableData(props?.data?.tableData)
    setTableParams({ ...tableParams, total: props?.data?.paginationDetails })

  }, [props]);

  useEffect(() => {
    GET_REGISTERED_PARTNERS().then((resp) => {
      if (resp?.status === 200) {
        setpartnerList(resp?.data?.data)
      }
      else {
        toastRef.current = toast.error("Partner list not found", {
          position: "top-right"
        });
      }
    })
  }, [])

  const CSVheaders = propsData?.titles?.map((item, index) => {
    return { 'label': item, 'key': propsData.dataIndexes[index] }
  })

  const { titles, dataIndexes } = propsData;

  const columns = titles && dataIndexes ? titles.map((title, index) => {
    if (title === 'Status' && pathname === '/dashboard/PartnerListing') {
      return {
        title,
        dataIndex: dataIndexes[index],
        render: (_, { partnerStatus }) => (
          <Tag color={partnerStatus == 1 ? 'green' : 'red'} key={partnerStatus}>
            {partnerStatus == 1 ? 'active' : 'inactive'}
          </Tag>
        )
      };
    }
    else if (title === 'Status' && pathname === '/dashboard/DeviceListing') {
      return {
        title,
        dataIndex: dataIndexes[index],
        render: (_, { deviceStatus }) => (
          <Tag color={deviceStatus == 1 ? 'green' : 'red'} key={deviceStatus}>
            {deviceStatus == 1 ? 'active' : 'inactive'}
          </Tag>
        )
      };
    }
    else if (title === 'Status' && (pathname === '/dashboard/MoviesContent' ||
      pathname === '/dashboard/TvshowsContent' || pathname === '/dashboard/MusicContent'
      || pathname === '/dashboard/GamesContent')) {
      return {
        title,
        dataIndex: dataIndexes[index],
        render: (_, { id, status }) => (
          <Switch
            checked={switchStates[id] || status === 1 ? true : false}
            onChange={() => handleChange(id, status)}
          />
        ),
        filters: [
          {
            text: 'Active',
            value: 1,
          },
          {
            text: 'Inactive',
            value: 0,
          },
        ],
        onFilter: (value, record) => record.status === value,
        filterSearch: true,
        width: '40%',
      };
    }
    else {
      return {
        title,
        dataIndex: dataIndexes[index],
      };
    }
  }) : [];


  if (pathname === '/dashboard/PartnerListing' || pathname === '/dashboard/DeviceListing' || pathname === '/dashboard/KenyaCitiesList') {
    columns.push({
      title: 'Action',
      dataIndex: 'Action',
      render: (_, record) =>
        <>
          {
            pathname === '/dashboard/KenyaCitiesList' ? '' :
              <span className='text-primary mr-2 link-btn' data-toggle="modal" data-target="#EditListing" onClick={() => setEditRowData(record)}>Edit</span>
          }
          <Popconfirm title="Sure to delete?" onConfirm={() => pathname === '/dashboard/KenyaCitiesList' ? handleDelete(record.id) : handleDelete(record.deviceId)}>
            <span className='text-primary link-btn'>Delete</span>
          </Popconfirm>
        </>
    })
  }

  const handleDelete = (key) => {
    if (pathname === '/DeviceListing') {
      DELETE_A_DEVICE(key).then((data) => {
        if (data?.status === 200) {
          const newData = tableData?.filter((item) => item.deviceId !== key);
          settableData(newData);
        }
        else {
          toastRef.current = toast.error("Something went wrong / Data not found ", {
            position: "top-right"
          });
        }

      }).catch((error) => {
        console.log(error)
      })
    }

    if (pathname === '/dashboard/PartnerListing') {
      DELETE_A_PARTNER(key).then((data) => {
        if (data?.status === 200) {
          const newData = tableData?.filter((item) => item.deviceId !== key);
          settableData(newData);
        }
        else {
          toastRef.current = toast.error("Something went wrong / Data not found ", {
            position: "top-right"
          });
        }

      }).catch((error) => {
        console.log(error)
      })
    }

    if (pathname === '/dashboard/KenyaCitiesList') {
      DELETE_A_CITY(key).then((data) => {
        if (data?.status === true) {
          const newData = tableData?.filter((item) => item.id !== key);
          settableData(newData);
        }
        else {
          toastRef.current = toast.error("Something went wrong / Data not found ", {
            position: "top-right"
          });
        }

      }).catch((error) => {
        console.log(error)
      })
    }


  };

  const UpdateEntryData = (type, e) => {
    setEditRowData({ ...EditRowData, [type]: e.target.value });
  }

  const saveUpdatedChanges = () => {
    console.log(EditRowData)
    UPDATE_A_DEVICE(EditRowData).then((res) => {
      console.log(res)
      props.callbackLoadDevices()
    }
    ).catch((error) => console.log(error))
  }

  const getSelectedFilterlogType = (data) => {
    console.log(data)
    if (propsData?.spinnerVisible === false) {
      callbackLoadFilteredLogs(data?.selectedValues)
    }
  }

  return (
    <div className="content-wrapper">
      <ToastContainer />

      <section className="content dataTableContent">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title" dangerouslySetInnerHTML={{ __html: propsData?.heading }}></h3>
                  <div className='filterBox'>
                    {
                      pathname === '/dashboard/otherLogs' && propsData &&
                      <MuiltipleSelectPicker data={{ 'values': props?.data?.subjects }} callbackOnchange={getSelectedFilterlogType} />
                    }
                    {(tableData?.length > 1) &&
                      <div> <CSVLink data={tableData} filename={`Aerohub-${propsData?.type}-data.csv`} headers={CSVheaders} className='text-decoration-none'> Download CSV </CSVLink></div>}
                  </div>
                </div>
                <div className="card-body">
                  {
                    propsData?.spinnerVisible === true ?
                      <Spinner />
                      : <Table columns={columns}
                        pagination={tableParams}
                        onChange={handleTableChange}
                        dataSource={tableData?.map((item) => ({ ...item, 'key': item.id }))} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* // Modal to open for disable / enable status  */}
      {
        modal2Open &&
        <Modal
          title="Are you sure want to ?"
          centered
          open={modal2Open}
          onOk={() => updateContentstatus()}
          onCancel={() => setModal2Open(false)}
        >
          <h6>{statusUpdate?.status == 1 ? 'Disable' : 'Enable'} this Content</h6>
        </Modal>
      }

      {/* //Edit Modal */}
      <div className="modal fade" id="EditListing" tabIndex="-1" role="dialog" aria-labelledby="EditListingTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="UpdateInformation">Update Information</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

              {pathname === '/dashboard/PartnerListing' ?
                <> {/* // Partner Edit Form */}
                  <form>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="partnerName">Partner Name</label>
                        <input type="text" className="form-control" id="partnerName" value={EditRowData?.partnerName} placeholder="Enter name" />
                      </div>

                      <div className="form-group">
                        <label>Status</label>
                        <select className='form-control' value={EditRowData?.partnerStatus}>
                          <option value={0}>InActive</option>
                          <option value={1}>Active</option>
                        </select>
                      </div>

                      <div className='row'>
                        <div className='col-6'>
                          <div className="form-group">
                            <label >Start Date</label>
                            <DatePicker className="form-control"
                              selected={EditRowData?.partnerStartDate}
                              // onChange={(date) => setEndDate(date)} 
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select" />
                          </div>
                        </div>

                        <div className='col-6'>
                          <div className="form-group">
                            <label >End Date</label>
                            <DatePicker className="form-control"
                              selected={EditRowData?.partnerEndDate}
                              // onChange={(date) => setEndDate(date)} 
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select" />
                          </div>
                        </div>
                      </div>

                    </div>
                  </form>
                </>
                : ''}

              {pathname === '/dashboard/DeviceListing' ?
                <> {/* // Device Edit Form */}
                  <form>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="DeviceName">Device Name</label>
                        <input type="text" className="form-control" value={EditRowData?.deviceName} onChange={(e) => UpdateEntryData('deviceName', e)} id="DeviceName" placeholder="Enter name" />
                      </div>

                      <div className="form-group">
                        <label>Choose Partner Name / ID</label>
                        <select className='form-control' value={EditRowData?.partnerId} onChange={(e) => UpdateEntryData('partnerId', e)}>
                          {
                            partnerList?.map((obj) => {
                              return <option key={obj.id} value={obj.partnerId}>{obj.partnerName} {'- ' + obj.partnerId}</option>
                            })
                          }
                        </select>
                      </div>

                      <div className="form-group">
                        <label>Status</label>
                        <select className='form-control' value={EditRowData?.deviceStatus} onChange={(e) => UpdateEntryData('deviceStatus', e)}>
                          <option value={0}>InActive</option>
                          <option value={1}>Active</option>
                        </select>
                      </div>

                    </div>
                  </form>
                </>
                : ''}

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={saveUpdatedChanges}>Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


