import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import './index.css'
import { useDispatch } from 'react-redux';
import { updateDateRange } from '../../Redux/reducers/filterSlice';

const MyDateRangePicker = ({buttonshowpicker,picker }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const dispatch = useDispatch();
  
  useEffect(()=>{
    setShowPicker(buttonshowpicker)
  },[buttonshowpicker])

  const handleSelect = (ranges) => {
    setDateRange(ranges.selection);
  };

  useEffect(() => {
    if (showPicker) {
      document.body.style.overflow = "hidden";
  
      // Clean up the side effect when the component unmounts
      return () => {
        document.body.style.overflow = "visible";
      };
    }
  
    // Return a cleanup function to reset the body overflow when showPicker is false
    document.body.style.overflow = "visible";
  }, [showPicker]);

  const handleAccept = () => {
    picker(false);
    dispatch(updateDateRange(dateRange))
    setShowPicker(false);
  };

  const handleCancel = () => {
    // Reset the date range or handle as needed
    picker(false);
    setShowPicker(false);
  };
  
  // useEffect(() => {
  //   setShowPicker(!showPicker)
  // }, [])

  return (
    <>
      <div className='DateRangePicker'>
        {showPicker && (
          <>
            <div className='DatepickerBG'></div>
            <div className='picker-container'>
              <h5 className='text-center mb-4 font-weight-bold'>Select date range to display data</h5>
              <DateRangePicker ranges={[dateRange]} onChange={handleSelect} />
              <div className='d-flex justify-content-end'>
              <button className='btn btn-primary mr-3' onClick={handleAccept}>Accept</button>
              <button className='btn btn-secondary' onClick={handleCancel}>Cancel</button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MyDateRangePicker;
