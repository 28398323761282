import React, { useEffect, useState } from 'react'
import { Datatablefile } from '../../../Vendor/Datatablefile'
import { GET_CABINDEFECT_EVENTS } from '../../../config/form_functions'

export const CabinDefectLogs = () => {

  const [defectData, setdefectData] = useState([])

  const tabledata = {
    type: 'cabin defects',
    heading: `<b>Total : </b>${defectData?.length}`,
    tableData: defectData,
    spinnerVisible: false,
    titles:['Id', 'Flight', 'Device', 'Name','Seat','Defect','Description','Synctime'],
    dataIndexes:['id', 'flight_number', 'deviceId', 'passenger_name','seat_number','defect_name','description','syncDatetime']
  }
  
  useEffect(() => {
    tabledata["spinnerVisible"] = true
    GET_CABINDEFECT_EVENTS()
      .then((resp) => {
        if (resp?.data?.status === true) {
          setdefectData(resp?.data?.data)
    tabledata["spinnerVisible"] = false
        }
      })
      .catch((err) => console.log("Data not found Msg :", err))
  }, [])

  return (
    <div className="wrapper dashboard-bg">
      {/* <div className="preloader flex-column justify-content-center align-items-center">
        <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60" />
      </div> */}
      <Datatablefile data={tabledata} />
    </div>
  )
}

