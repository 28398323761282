import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../assets/images/logo_aerohub.png'
import { faHouse, faCloudArrowUp, faImage } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

export const MagazineSidebar = () => {

    const [pageroute, setpageroute] = useState(window.location.pathname)
    const selector = useSelector((state) => state.AuthSlice.userAccess)
    const location = useLocation()

    useEffect(() => {
        setpageroute(location.pathname)
    }, [location.pathname])

    return (
        <>
            <aside className="main-sidebar elevation-4">
                {/* <aside className="main-sidebar sidebar-dark-primary elevation-4"> */}

                <Link to="/dashboard/Home" className="brand-link">
                    <img src={logo} alt="Aerohub Logo" className="brand-image"
                        style={{ "opacity": ".8" }} />
                    {/* <span className="brand-text">Aerohub</span> */}
                </Link>

                <div className="sidebar">
                    <div className="form-inline">
                        {/* <div className="input-group" data-widget="sidebar-search">
                            <input className="form-control form-control-sidebar" type="search" placeholder="Search"
                                aria-label="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-sidebar">
                                    <i className="fas fa-search fa-fw"></i>
                                </button>
                            </div>
                        </div> */}
                    </div>

                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">

                            {/* <li className="nav-item menu-open"> */}

                            <li className="nav-item">
                                <Link to='/dashboard/Home' className={`${pageroute === '/dashboard/Home' ? 'active-menu-item' : ''} nav-link`}>
                                    <FontAwesomeIcon icon={faHouse} />
                                    <p> Dashboard </p>
                                </Link>
                            </li>

                            <li className="nav-item">
                                {/* <i className="nav-icon fas fa-th"></i> */}
                                <Link to="/dashboard/MagazineListing" className={`${pageroute === '/dashboard/MagazineListing' ? 'active-menu-item' : ''} nav-link`}>
                                    <FontAwesomeIcon icon={faImage} />
                                    <p>
                                        Magazine Listing
                                    </p>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/dashboard/MagazineUpload" className={`${pageroute === '/dashboard/MagazineUpload' ? 'active-menu-item' : ''} nav-link`}>
                                    <FontAwesomeIcon icon={faCloudArrowUp} />
                                    <p>
                                        Magazine Upload
                                    </p>
                                </Link>
                            </li>
                        </ul >
                    </nav >
                </div >
            </aside >
        </>
    )
}
